<template>
  <div class="home">
    <navbar :menulist="$store.state.mainmenu"></navbar>
    <herobig :title="$t('title')"></herobig>

    <h2 class="welcome">{{$t('subtitleAlma')}}</h2>

    <boxcontainer>
        <div class="row">
          <div :class="['mx-auto col-lg-'+box.w]" v-for="box in boxes" :key="box.id">
            <box
              :imgname="box.imgname" 
              :alttext="$t('boxesAlma'+box.id+'.alttext')"
              :title="$t('boxesAlma'+box.id+'.title')" 
              :menulist="box.menulist"
              :item="box.item"
              :disabled="box.disabled"
              :extlink="box.extlink"
            >
            </box>
          </div>
        </div>
    </boxcontainer>

    <footerline></footerline>
  </div>
</template>

<script>
  // @ is an alias to /src
  import navbar       from '@/components/navbar.vue'
  import herobig      from '@/components/herobig.vue'
  import box          from '@/components/box.vue'
  import boxcontainer from '@/components/boxcontainer.vue'
  import footerline   from '@/components/footerline.vue'

  export default {
    name: 'MyAlmaLife',
    components: {
      navbar,
      herobig,
      box,
      boxcontainer,
      footerline
    },
    data: function () {
      return {
        boxes: []
      }
    },
    computed: {
      housingok: function() {
        return this.$store.getters.housing
      }
    },
    watch: {
      housingok: function() {
        console.log('reload tabs')
        this.loadTabs()
      }
    },
    mounted: function() {
      this.loadTabs()
    },
    methods: {
      loadTabs: function() {
        var w = 12/4
        
        this.boxes.splice(0,this.boxes.length)

        /*
        this.boxes.push({ 
          id: 1,
          w: w,
          imgname: 'img/infogenerali.jpg',
          alttext: 'Regolamento',
          title: 'Regolamento',
          menulist: ['Open Day','Didattica','News'],
          item: this.$store.state.mainmenu[1],
          extlink: 'https://www.alma.scuolacucina.it/chi-vuole-iscriversi/'
        })
        */

        this.boxes.push({
          id: 2,
          w: w,
          disabled: !this.$store.getters.studente,
          imgname: 'img/primogiorno.jpg',
          item: {title:'DayFirst', path:'DayFirst'}
        })

        this.boxes.push({
          id: 3,
          w: w,
          disabled: !this.$store.getters.studente && !this.$store.getters.dipendente,
          imgname: 'img/areastudenti.jpg',
          item: {title:'Studente', path:'Studente'}
        })

        this.boxes.push({
          id: 4,
          w: w,
          disabled: !this.$store.getters.housing,
          imgname: 'img/housing.jpg',
          item: {title:'Housing', path:'Housing'}
        })
        if(this.$i18n.locale=='it') {
          this.boxes.push({
            id: 6,
            w: w,
            imgname: 'img/sponsor.jpg',
            item: { title: 'Area Sponsor', path: 'AreaSponsor' }
          })
        }
        this.boxes.push({
          id: 5,
          w: w,
          disabled: !this.$store.getters.studente,
          imgname: 'img/diplomati.jpg',
          item: {title:'Diplomati', path:'Diplomati'}
        })
      }
    }
  }
</script>