<template>
  <div>
    <ul class="nav nav-tabs" role="tablist">
      <li role="presentation" class="active" v-for="(icon, i) in icons" v-bind:key="icon.id">
        <a href="" class="nav-link" v-bind:aria-controls="icon.title" role="tab" v-on:click.prevent="goto(i)" v-bind:title="icon.title">
          <span class="round-tab oi" v-bind:class="iconstyle(icon)" v-bind:title="icon.title" aria-hidden="true"></span>
        </a>
      </li>
    </ul>
    <div id="sxtab" class="tab-content" v-for="(icon, i2) in icons" v-bind:key="icon.id">
      <div v-bind:id="icon.title" v-bind:class="{'active': i2==activeTab}" class="tab-pane">
        <slot v-bind:name="icon.name"></slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'icontabs',
    // id title iconname color
    props: {
      icons: Array
    },
    data: function () {
      return {
        show: false,
        activeTab: 0,
      }
    },
    computed: {
    },
    methods: {
      iconstyle: function(icon) {
        var s=[];
        if (icon.color=='red') s.push('iconRed');
        if (icon.color=='yellow') s.push('iconYellow');
        if (icon.color=='green') s.push('iconGreen');
        if (icon.color=='grey') s.push('iconGrey');
        if (icon.iconname) s.push('oi-'+icon.iconname);
        return s;
      },
      goto: function (item) {
        this.activeTab = item;
        this.$emit('change', item);
      }
    }
  }
</script>

<style scoped>

.iconRed{
    color:lightgrey;
    color:red;
}

.iconYellow{
    color:gold;
}

.iconGreen{
    color:green;
}

.iconGrey{
    color:grey;
}

.round-tab {
    width: 35px;
    height: 35px;
    line-height: 35px;
    border-radius: 100px;
    font-size: 16px;
    text-align: center;
}

</style>