<template>
    <div class="home">
        <navbar :menulist="$store.state.mainmenu" />

        <div class="row mx-auto pt-2">
            <div class="col-lg-12 col-md-12 col-xs-12 ml-2">
                <button type="button" class="btn btn-outline-dark btn-sm" @click="back">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                    </svg>
                    {{$t('Indietro')}}
                </button>
            </div>
        </div>

        <div :class="{disabled: !this.$store.getters.isEcommerceVisible}">
            <h2 class="welcome">
                Storico ordini
                <i @click="showleg = true" class="bi bi-info-circle-fill"></i>
            </h2>

            <div class="row mx-auto justify-content-center mb-3" v-for="o in orders" :key="o.Id">
                <div v-if="o.Righe_Ordine__r" class="col-md-7">
                    <div class="card">
                        <div class="card-header">
                            Ordine n. {{ o.N_Ordine__c }} - {{ o.CreatedDate | date }}
                        </div>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item" v-for="r in o.Righe_Ordine__r.records" :key="r.Id">
                                <div class="row">
                                    <div class="col-2">
                                        <i class="bi fa-2x" :class="statusIcon(r.Stato__c)"></i>
                                    </div>
                                    <div class="col-8 px-0">
                                        {{r.Descrizione__c}}
                                        <span v-if="r.Info_Prenotazione__c"><br>{{r.Info_Prenotazione__c}}</span>
                                        <span v-if="r.Tempo_approvvigionamento__c"><br>{{ $t('tempoApprovvigionamento') }} - {{ r.Tempo_approvvigionamento__c }} gg</span>
                                        <span v-if="r.Codice_Ricarica__c"><br>{{ $t('codiceRicarica') }}: {{ r.Codice_Ricarica__r.Codice__c }}</span>
                                    </div>
                                    <div class="col-2 pl-0 text-right">{{r.Quantita__c || 1}}x {{r.Prezzo__c | amount}}</div>
                                </div>
                            </li>
                        </ul>
                        <div class="card-footer text-muted text-right">
                            Tot.: {{ o.Amount | amount }}
                        </div>
                    </div>
                </div>
            </div>

            <!-- Legenda icone (Modal) -->
            <div class="modal fade" tabindex="-1" :class="showleg ? 'd-block':''">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title font-weight-bold">Legenda icone</h5>
                            <button class="close" @click="showleg = false"><span>&times;</span></button>
                        </div>
                        <div class="modal-body">
                            <p style="font-size: 18px">
                                <i class="bi bi-hourglass-split text-warning"></i> <i class="bi bi-arrow-right"></i> In attesa<br>
                                <i class="bi bi-bag-fill text-success"></i>  <i class="bi bi-arrow-right"></i> Pronto per il ritiro <br>
                                <i class="bi bi-check-circle"></i>    <i class="bi bi-arrow-right"></i> Consegnato / Ricarica accreditata
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <footerline />
    </div>
</template>

<script>
    import navbar     from '@/components/navbar.vue'
    import footerline from '@/components/footerline.vue'

    import numeral from 'numeral'

    export default {
        name: 'EcommerceStorico',
        components: { navbar, footerline },
        data: function() {
            return {
                orders: [],
                showleg: false
            }
        },
        filters: {
            amount: v => numeral(v).format('0,0[.]00$'),
            date:   d => new Date(d).toLocaleString('it-IT').slice(0, -3)
        },
        mounted: function() {
            this.loadOrders()
        },
        methods: {
            back: function() {
                this.$router.push({ name: 'EcommerceHome' }) 
            },
            loadOrders: function() {
                this.$store.dispatch('getOrdersEcommerce')
                .then(result => {
                    console.log('getOrdersEcommerce',result)
                    this.orders = result
                })
            },

            statusIcon: function(status) {
                switch (status) {
                    // case '???': return 'bi-hourglass-split'
                    case 'Ordinato': 
                        return 'bi-hourglass-split text-warning'
                    case 'Scontrinato': 
                        return 'bi-hand-thumbs-up'
                    case 'Notificato': 
                        return 'bi-bag-fill text-success'
                    case 'Consegnato': 
                        return 'bi-check-circle'
                    default: return ''
                }
            }
        }
    }
</script>

<style>
    .disabled{
        pointer-events: none;
        opacity: 0.4;
    }
</style>