<template>
  <div class="home">
    <navbar v-bind:menulist="$store.state.mainmenu" ></navbar>
    <herobig v-bind:title="$t('title')"></herobig>

    <h2 class="welcome">{{$t('subtitleStoricoCandidature')}}</h2>

    <boxcontainer>

      <div class="row">
        <div class="col-12 mx-auto">
          <almatable v-bind:cols="cstorico" v-bind:rows="rstorico"></almatable>
        </div>
      </div>
    </boxcontainer>
    <footerline></footerline>

  </div>
</template>

<script>
  // @ is an alias to /src
  import navbar from '@/components/navbar.vue'
  import herobig from '@/components/herobig.vue'
  import boxcontainer from '@/components/boxcontainer.vue'
  import footerline from '@/components/footerline.vue'
  import almatable from '@/components/almatable.vue'

  export default {
    name: 'Home',
    components: {
      navbar,
      herobig,
      boxcontainer,
      footerline,
      almatable,
    },
    data: function () {
      return {
        // Inserimento Storico Candidature
        cstorico: [ 
        ],
        rstorico: [
          
        ],
      };
    },
    mounted: function() {
      var self=this;
      this.setStorico();
      this.$store.dispatch('getCandidatura').then(function() {
        console.log('candidature recuperate')
        console.log(self.$store.state.candidatura)
        if (self.$store.state.candidatura) {
          self.$store.state.candidatura.forEach(function(c) {
            console.log(c)
            var statotradotto = self.$i18n.t(c.Stato_Candidatura__c);
            self.rstorico.push( {
             Id: c.Id,
              //tcorso: c.Corso__r.Name,
              nedizione: c.Edizione_Corso__r.Name,
              cdata: c.CreatedDate ,
              cstato: statotradotto
              });
          })
        }
      });
    },
    methods:{
      setStorico:function(){
        var self = this;
        this.cstorico.push(
          // { title:this.$i18n.t('Corso'), name:'tcorso' },
          { title:self.$i18n.t('Corso'), name:'nedizione' },
          { title:self.$i18n.t('Data'),name:'cdata', filter:'date' },
          { title:self.$i18n.t('Stato'), name:'cstato' }
        );
      }
      
    }
  }
</script>