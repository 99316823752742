<template>
  <div class="home">
    <navbar v-bind:menulist="$store.state.mainmenu"></navbar>
    <herobig v-bind:title="$t('title')"></herobig>

    <h2 class="welcome">{{$t('subtitleProfiloPersonale')}}</h2>

    <boxcontainer>
      <div class="row">
        <div class="col">
          <signup v-if="showSignup && !$store.getters.logged" v-on:cancel="showSignup=false"></signup>
          <signin v-if="!showSignup && !$store.getters.logged" v-on:signup="showSignup=true"></signin>
          <datipersonali v-if="$store.getters.logged" v-on:saveprofilo="saveprofilo($event)" v-on:cancel="cancel"></datipersonali>
        </div>
      </div>
    </boxcontainer>

    <footerline></footerline>
  </div>
</template>

<script>
// @ is an alias to /src
import navbar from "@/components/navbar.vue";
import herobig from "@/components/herobig.vue";
import boxcontainer from "@/components/boxcontainer.vue";
import footerline from "@/components/footerline.vue";
import signup from "@/components/signup.vue";
import signin from "@/components/signin.vue";
import datipersonali from "@/components/datipersonali.vue";

export default {
  name: "Home",
  components: {
    navbar,
    herobig,
    boxcontainer,
    footerline,
    datipersonali,
    signup,
    signin
  },
  data: function() {
    return {
      fakeLoginStatus: false,
      showSignup: false
    };
  },
  mounted: function() {},
  methods: {
    cancel: function() {
      this.$store.dispatch('getProfile');
    },
    saveprofilo: function(confermaDato) {
      console.log('confermaDato',confermaDato)
      console.log(this.$store.state);
      var self = this;
      var tmpOppty = this.$store.state.candidatura[0];
      delete tmpOppty.attributes;
      if(confermaDato == 'FatturazioneCorsi')this.$store.state.userprofile.idOppty_Fatturante__c = this.$store.state.candidatura[0].Id;
      if(confermaDato == 'FatturazioneHousing')this.$store.state.userprofile.idOppty_Fatturante_Housing__c = self.$store.state.opptyHousingFatt.Id;
      this.$store.state.userprofile.tmp_FirstName__c = this.$store.state.userprofile.FirstName;
      this.$store.state.userprofile.tmp_LastName__c = this.$store.state.userprofile.LastName;
      this.$store.dispatch('updateProfilo').then(function(data){
        if(data == null){
          alert('Errore nel salvataggio! Contattare l\'amministrazione.');
        }else{

          if(confermaDato == 'FatturazioneCorsi'){
            tmpOppty.Data_compilazione_dati_fatt__c = Date.now();
            console.log('funz save');
            console.log(tmpOppty);
            self.$store.dispatch('updateCandidatura', tmpOppty).then(function(){
              alert('Dati fatturazione aggiornati correttamente!');
            }).catch(function(err) {
              console.log('problemi:')
              console.log(err)
              console.log('problemi:')
              console.log(err.response.data)
              alert('Errore nel salvataggio! Contattare l\'amministrazione.');
            });
          }
          if(confermaDato == 'FatturazioneHousing'){
            var tmpOpptyHoysing = self.$store.state.opptyHousingFatt;
            delete tmpOppty.attributes;
            tmpOpptyHoysing.Data_compilazione_dati_fatt_Housing__c = Date.now();
            console.log('funz save');
            console.log(tmpOpptyHoysing);
            self.$store.dispatch('updateCandidatura', tmpOpptyHoysing).then(function(){
              alert('Dati fatturazione aggiornati correttamente!');
            }).catch(function(err) {
              console.log('problemi:')
              console.log(err)
              console.log('problemi:')
              console.log(err.response.data)
              alert('Errore nel salvataggio! Contattare l\'amministrazione.');
            });
          }
        }
      }, this);
        console.log(this.$store.state.userprofile);
      

    },
    setFakeLogin: function() {
      this.fakeLoginStatus=!this.fakeLoginStatus;
      this.$store.commit('SET_FAKELOGIN', this.fakeLoginStatus );
    }
  }
};
</script>