<template>
<div class="row">
 <div class="col-lg-12 col-md-11 col-xs-12 ">
   <div>
     <span class="col-12 font-weight-bold">{{$t('Periodo')}}:</span>
  <select class="custom-select custom-select-sm mb-1 w-50" id="inputGroupSelect01" size="1"
    v-on:change="changePer" v-model="filtroSel">
    <option selected value="">{{$t('Seleziona')}}</option>
    <option v-for="ma in mesiAnno" v-bind:key="ma.val" v-bind:value="ma">{{ma.val}}</option>
  </select>
   </div>
   <h2 class="mt-3">{{$t('CorsiModuliDisponibili')}}</h2>
   <div class="table-responsive-sm">
    <table class="table" style=" ">
      <thead class="bg-alma text-white">
        <tr>
          <th scope="col">{{$t('DataInizio')}}</th>
          <th scope="col">{{$t('DataFine')}}</th>
          <th scope="col">{{$t('aperturaIscr')}}</th>
          <th scope="col">{{$t('Entro')}}</th>
          <th scope="col">{{$t('NomeModulo')}}</th>
          <th scope="col">{{$t('NMinPers')}}</th>
          <th scope="col">{{$t('PostiDisponibili')}}</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(e, index) in showeventi" >
        <tr v-bind:key="e.Id">
          <td>{{e.dEvento}}</td>
          <td>{{e.dFine}}</td>
          <td>{{e.aperturaPren}}</td>
          <td>{{e.entro}}</td>
          <td v-if="e.Link__c"><a v-on:click="gotoLink(e)" target="_blank"><u><i>{{e.Nome_Evento__c}}</i></u></a></td>
          <td v-else>{{e.Nome_Evento__c}}</td>
          <td>{{e.Campienza_MIN__c}}</td>
          <td>{{e.posti}}</td>
          <td>
            <button type="button" v-bind:class="e.stato == 'Partecipo'?'btn btn-info':e.stato == 'Completo'?'btn btn-light':'btn btn-danger'" 
              v-bind:disabled="statodis(e)" v-on:click="iscrivimiOannulla(index)">{{e.stato}}</button></td>

        </tr>
        <tr v-if="e.Descrizione__c" v-bind:key="index"><td style="border: none; padding: 0px 0px 12px 12px" colspan="6"><i>{{e.Descrizione__c}}</i></td></tr>
          
        </template>
      </tbody>
    </table>
</div>

 </div>
 </div>
</template>


<script>
  export default {
    name: 'tabs',
    props: {
      tabs: Array
    },
    data: function () {
      return {
        show: false,
        activeTab: 0,
        eventi:[],
        showeventi:[],
        mesiAnno:[],
        filtroSel: {},
        busy: false
      }
    },
    mounted: function(){
      var self = this;
      var options = { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric' };
      var options2 = { month: 'long', year: 'numeric'};
      var options3 = { weekday: 'short' };
      var accountId = this.$store.state.userprofile.Id
      this.$store.dispatch('getEventi',{tipo:'Corsi', data: '',oppty: this.$store.state.idOppty}).then(function(resp){
        self.$store.dispatch('getPrenotazione', {idAccount: accountId, tipo: 'Corsi'}).then(function(data){
          console.log('get prenotazione');
          resp.forEach(element => {
            var dataI = self.$i18n.locale=='it'?new Date(element.Data__c).toLocaleDateString("it-IT", options2):new Date(element.Data__c).toLocaleDateString("en-US", options2);
            dataI = dataI.charAt(0).toUpperCase() + dataI.slice(1);
            var row = {val: dataI, mese: new Date(element.Data__c).getMonth(), anno: new Date(element.Data__c).getFullYear()};
            var exists = self.mesiAnno.some(function(field) {
              return field.val === row.val;
            });
            if(!exists)self.mesiAnno.push(row);

            if(element.Capienza_MAX__c == element.Occupazione_attuale__c) element.stato = 'Completo';
            else element.stato='Partecipo';
            element.posti = element.Occupazione_attuale__c? parseInt(element.Capienza_MAX__c - element.Occupazione_attuale__c) : parseInt(element.Capienza_MAX__c);
            data.forEach(function(d){
              if(d.Calendario__c == element.Id){
                element.stato = 'Annulla';
              }
              if( element.Gruppo_Evento__c && d.Calendario__r.Gruppo_Evento__c == element.Gruppo_Evento__c) element.eventoBloc=true;
            });
            if(element.Data__c){
              var dataEv  = new Date(element.Data__c);
              element.dEvento = self.$i18n.locale=='it'?dataEv.toLocaleDateString("it-IT", options):dataEv.toLocaleDateString("en-US", options);
              var arr = element.dEvento.charAt(0).toUpperCase() + element.dEvento.slice(1);
              element.dEvento=arr;
              if(!element.Chiusura_Prenotazioni__c){
                element.entro = element.Data__c;
                element.Chiusura_Prenotazioni__c = element.Data__c;
              }
            }
            if(element.Data_Fine__c){
              var dataFin  = new Date(element.Data_Fine__c);
              element.dFine = self.$i18n.locale=='it'?dataFin.toLocaleDateString("it-IT", options):dataFin.toLocaleDateString("en-US", options);
              var arr1 = element.dFine.charAt(0).toUpperCase() + element.dFine.slice(1);
              element.dFine=arr1;
            }
            if(element.Chiusura_Prenotazioni__c){
              var dataEntr  = new Date(element.Chiusura_Prenotazioni__c);
              element.entro = self.$i18n.locale=='it'?dataEntr.toLocaleDateString("dataEntr", options):dataEntr.toLocaleDateString("en-US", options);
              var arr2 = element.entro.charAt(0).toUpperCase() + element.entro.slice(1);
              element.entro=arr2 +' '+ '00:00'
            }
            if(element.Apertura_Prenotazioni__c) {
              var giornoOra = new Date(element.Apertura_Prenotazioni__c)
              element['aperturaPren'] = self.$i18n.locale=='it'? giornoOra.toLocaleDateString("dataEntr", options3) : giornoOra.toLocaleDateString("en-US", options3)+' '
              var arr3 = element.aperturaPren.charAt(0).toUpperCase()  + element.aperturaPren.slice(1)
              element.aperturaPren = arr3 +' '+ ('00' + giornoOra.getDate()).slice(-2)+'/'
                                    +('00' + (giornoOra.getMonth()+1)).slice(-2)+'/'
                                    +giornoOra.getFullYear()+' '
                                    +('00' + giornoOra.getHours()).slice(-2)+":"
                                    +('00' + giornoOra.getMinutes()).slice(-2)
            }
            self.eventi.push(element);
          });
          self.eventi.sort(self.compare2);
          self.mesiAnno.sort(self.compare);
        })
      });
    },
    methods: {
      compare: function( a, b ) {
        if ( a.ord < b.ord ){
          return -1;
        }
        if ( a.ord > b.ord ){
          return 1;
        }
        return 0;
      },
      compare2: function( a, b ) {
        var dateA = new Date(a.dEvento);
        var dateB = new Date(b.dEvento);
        return dateA - dateB;
      },
      goto: function (item) {
        this.activeTab = item;
        this.$emit('change', item);
      },
      iscrivimiOannulla: function(index){
        this.busy = true;
        var evento = this.showeventi[index];
        console.log(evento.Id);
        var self = this;

        if(evento.eventoBloc){
          alert('Questo evento non è compatibile con un’altra attività cui ti sei già iscritto');
          this.busy = false;
          return;
        }
        if(evento.Obbligatorio__c && evento.stato == 'Annulla'){
          alert('Contatta l’organizzazione: accoglienza@scuolacucina.it');
          this.busy = false;
          return;
        }
        if(evento.stato == 'Partecipo'){
          if (confirm('Confermi prenotazione?')) {
            var idOppty = ''
            if(this.$store.state.idOppty != '') idOppty = this.$store.state.idOppty
            this.$store.dispatch('createPrenotazione', {idEvento: evento.Id, idOppty: idOppty}).then(function(resp) {
              self.busy = false;
              alert(resp);
              window.location.reload();
            }).catch(function(err){
              self.busy = false;
              console.log('problemi', err)
            });
          }
        }else{
          if (confirm('Confermi annullamento?')) {
            this.$store.dispatch('annullaPrenotazione', evento.Id).then(function(resp) {
              self.busy = false;
              alert(resp);
              window.location.reload();
            }).catch(function(err){
              self.busy = false;
              console.log('problemi', err)
            });
          }else{
              self.busy = false;
          }
        }
      },
      gotoLink: function(el){
        window.open(el.Link__c, '_blank');
      },
      statodis: function(el) {
        if(el.Chiusura_Prenotazioni__c != undefined && (new Date()).getTime() > new Date(el.Chiusura_Prenotazioni__c).getTime()) return true;
        if(el.Apertura_Prenotazioni__c != undefined && new Date(el.Apertura_Prenotazioni__c) > new Date()) return true;
        if(el.stato == 'Completo') return true;
        if(this.busy)return true;
        return false;
      },
      changePer: function(){
        console.log(this.filtroSel);
        var r = this.filtroSel;
        var self = this;
        if(r){
            console.log(JSON.stringify(r.val));
          this.showeventi = [];
          this.eventi.forEach(function(e){
            console.log(e);
            var mese, anno;
            mese = new Date(e.Data__c).getMonth();
            anno = new Date(e.Data__c).getFullYear();
            if(mese == r.mese && anno == r.anno){
              self.showeventi.push(e);
            }
          })
          this.sortByMese()
        }else{
          this.showeventi = [];
        }
      },
      sortByMese: function() {
        this.showeventi.sort((a, b) => new Date(a.Data__c) - new Date(b.Data__c)) // visualizza gli eventi in ordine cronologico
      }
    }
  }
</script>

<style scoped>
</style>
