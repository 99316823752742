<template>
 <div class="alert alert-dismissible almacolors" v-if="showInstallBanner">
   <div class="installami">
    {{$t('Installazione')}}
   </div>
    <button type="button" class="btn btn-light" v-on:click.prevent="install">{{$t('Installa')}}</button>
    <button type="button" class="close"  v-on:click.prevent="showInstallBanner=false">&times;</button>
  </div>
</template>

<script>
 let installEvent;
export default {
  name: 'installPrompt',
  data() {
    return {
      showInstallBanner: false
    };
  },
  created() {
    console.log('install prompter')
    window.addEventListener('beforeinstallprompt', e => {
      e.preventDefault();
      installEvent = e;
      this.showInstallBanner = true;
    });
    window.addEventListener('appinstalled', () => {
      console.log('app installed');
      // eslint-disable-next-line no-undef
      gtag('event', 'install', { 'send_to': 'GTM-KGMX463' });
      console.log('User accepted the install prompt');
    });    
    window.addEventListener('load', () => {
      if (navigator.standalone) {
        console.log('Launched: Installed (iOS)');
      } else if (matchMedia('(display-mode: standalone)').matches) {
        console.log('Launched: Installed');
      } else {
        console.log('Launched: Browser Tab');
      }
    });    
  },
  methods: {
    install() {
      this.showInstallBanner = false;
      installEvent.prompt();
      installEvent.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          // eslint-disable-next-line no-undef
          gtag('event', 'install', { 'send_to': 'GTM-KGMX463' });
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }        
        installEvent = null;
      });
    }
  }
};
</script>

<style scoped>
.almacolors {
  border-color: #cfa353;
  color:white;
  background-color: #314361;
}
.installami {
  display: inline;
  padding-right: 10px;
}
</style>