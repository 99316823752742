<template>
  <div class="col">
    <ul class="nav nav-tabs" id="spazionav">
      <li class="nav-item" v-for="(item, i1) in tabs" v-bind:key="item.title">
        <a v-bind:class="{'active': i1==activeTab}" class="nav-link" href="" v-on:click.prevent="goto(i1)">{{$t(item.title)}}</a>
      </li>
    </ul>
    <div class="tab-content" v-for="(item, i2) in tabs" v-bind:key="item.title">
      <div v-bind:id="item.title" v-bind:class="{'active': i2==activeTab}" class="tab-pane">
        <slot v-bind:name="item.name"></slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'tabs',
    props: {
      tabs: Array
    },
    data: function () {
      return {
        show: false,
        activeTab: 0,
      }
    },
    methods: {
      goto: function (item) {
        this.activeTab = item;
        this.$emit('change', item);
      }
    }
  }
</script>