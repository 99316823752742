<template>
  <div class="home">
    <navbar v-bind:menulist="$store.state.mainmenu" ></navbar>
    <herobig v-bind:title="$t('title')"></herobig>

    <h2 align="center" class="welcome">{{$t('subtitleRegolamentoHousing')}}</h2>

    <div class="row d-flex justify-content-center">
      <div class="text-center col-md-3 col-lg-3 col-xs-12 col-sm-12 p-4">
        <button class="btn btn-primary" @click.prevent="goto('descrizioneAlloggio')">{{$t('descrizioneAlloggio')}}</button>
      </div>

      <div class="text-center col-md-3 col-lg-3 col-xs-12 col-sm-12 p-4">
        <div v-if="$i18n.locale == 'en'">
          <a href="https://almascuola.my.salesforce.com/sfc/p/1t000000ss9x/a/7T000000UlPp/oB1rKwDgfcNC5e.6bNfeU5r3SBfGn0p3282Y8FJjkSc">
            <button class="btn btn-primary" type="button">{{$t('regolamentoHousing')}}</button>
          </a>
        </div>

        <div v-else>
          <button class="btn btn-primary" @click.prevent="goto('RegolamentoHousing')">{{$t('regolamentoHousing')}}</button>
        </div>
      </div>

      <div class="text-center col-md-3 col-lg-3 col-xs-12 col-sm-12 p-4">
        <div v-if="$i18n.locale == 'it'">
          <a href="https://almascuola.my.salesforce.com/sfc/p/1t000000ss9x/a/5J000000V7zW/ENsA5c5baSiCn74DQXTQjvw5HdYKheD2c5txzSHxL5Y">
            <button class="btn btn-primary" type="button">{{$t('rifiuti')}}</button>
          </a>
        </div>

        <div v-else>
          <button class="btn btn-primary" @click.prevent="goto('HousingRifiuti')">{{$t('rifiuti')}}</button>
        </div>
      </div>

      <div v-if="$i18n.locale == 'it'" class="text-center col-md-3 col-lg-3 col-xs-12 col-sm-12 p-4">
        <div v-if="$i18n.locale == 'it'">
          <a href="https://almascuola.my.salesforce.com/sfc/p/1t000000ss9x/a/SY000000Bspt/w9mD0GB4eEFOp1ZVJ29.FbalJ_ubAG_tTsWHHP04uPc">
            <button class="btn btn-primary" type="button">Manuale economia domestica</button>
          </a>
        </div>

        <!-- <div v-else>
          <button class="btn btn-primary" @click.prevent="goto('HousingRifiuti')">{{$t('rifiuti')}}</button>
        </div> -->
      </div>

      <div v-if="$i18n.locale == 'it'" class="text-center col-md-3 col-lg-3 col-xs-12 col-sm-12 p-4">
        <div v-if="$i18n.locale == 'it'">
          <a href="https://almascuola.my.salesforce.com/sfc/p/1t000000ss9x/a/SY000000BrXG/LMnqmASbGjnCXF9YrjsQ02UvVrdVmAbvfO5WRXdfdd4">
            <button class="btn btn-primary" type="button">Istruzioni raccolta differenziata</button>
          </a>
        </div>

        <!-- <div v-else>
          <button class="btn btn-primary" @click.prevent="goto('HousingRifiuti')">{{$t('rifiuti')}}</button>
        </div> -->
      </div>

      <div v-if="$i18n.locale == 'it'" class="text-center col-md-3 col-lg-3 col-xs-12 col-sm-12 p-4">
        <div v-if="$i18n.locale == 'it'">
          <a href="https://almascuola.my.salesforce.com/sfc/p/1t000000ss9x/a/SY000000Dtcz/ZESZiZZ.HnyozrVip8dGbS7cpXcgVuvEFY28wQvUtMc">
            <button class="btn btn-primary" type="button">Pianta campane vetro</button>
          </a>
        </div>

        <!-- <div v-else>
          <button class="btn btn-primary" @click.prevent="goto('HousingRifiuti')">{{$t('rifiuti')}}</button>
        </div> -->
      </div>
    </div>


    <footerline></footerline>
  </div>
</template>

<script>
  // @ is an alias to /src
  import navbar from '@/components/navbar.vue'
  import herobig from '@/components/herobig.vue'
  import footerline from '@/components/footerline.vue'

  export default {
    name: 'Home',
    components: {
      navbar,
      herobig,
      footerline
    },
    data: function() {
      return {
      }
    },
    methods: {
      goto: function(p) {
          this.$router.push({ name: p })
      }
    }
  }
</script>