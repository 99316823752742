<template>
  <div class="home">
    <navbar v-bind:menulist="$store.state.mainmenu" ></navbar>
    <herobig v-bind:title="$t('title')"></herobig>

    <h2 class="welcome">{{$t('subtitleRicette')}}</h2>

    <boxcontainer>

        <div class="row">

          <div v-bind:class="[' mx-auto col-lg-'+box.w]" v-for="box in boxes" v-bind:key="box.id">
            <box 
              v-bind:imgname="box.imgname" 
              v-bind:alttext="$t('boxesRicette'+box.id+'.alttext')"
              v-bind:title="$t('boxesRicette'+box.id+'.title')" 
              v-bind:menulist="box.menulist"
              v-bind:item="box.item"
              v-bind:extlink="$t('boxesRicette'+box.id+'.extlink')"
              >
            </box>
          </div>

        </div>

    </boxcontainer>
    <footerline></footerline>

  </div>
</template>

<script>
  // @ is an alias to /src
  import navbar from '@/components/navbar.vue'
  import herobig from '@/components/herobig.vue'
  import box from '@/components/box.vue'
  import boxcontainer from '@/components/boxcontainer.vue'
  import footerline from '@/components/footerline.vue'

  export default {
    name: 'Home',
    components: {
      navbar,
      herobig,
      box,
      boxcontainer,
      footerline
    },
    data: function () {
      return {
        boxes: []
      };
    },
    mounted: function() {
      var w=12/2;
      this.boxes.splice(0,this.boxes.length);
      /*this.boxes.push( { 
        id: 1,
        w: w,
        imgname: 'img/infogenerali.jpg'
      } );
      this.boxes.push( { 
        id: 2,
        w: w,
        imgname: 'img/candidatura.jpg',
        
      } );
      this.boxes.push( { 
        id: 3,
        w: w,
        imgname: 'img/candidatura.jpg',
        
      } );*/
      if(this.$i18n.locale=='it') {
        this.boxes.push( { 
          id: 4,
          w: w,
          imgname: 'img/eventialma.jpg'
        } );
      }
      this.boxes.push( { 
        id: 5,
        w: w,
        imgname: 'img/varia.jpg'
      } );
    }
  }
</script>