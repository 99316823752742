<template>
  <div class="home">
    <navbar v-bind:menulist="$store.state.mainmenu" ></navbar>
    <herobig v-bind:title="$t('title')"></herobig>

    <h2 align="center" class="welcome">{{$t('regolamentoHousing')}}</h2>

    <div class="row">
      <div class="col-10 mx-auto" v-if="this.$i18n.locale=='it'">
        <ol type="1">
          <li><b>REGOLE DI COMPORTAMENTO IN COLLETTIVITA’</b></li>
          <p class="par">In una residenza collettiva il comportamento del singolo deve essere improntato, nei rapporti con gli altri assegnatari e con il personale preposto, all'osservanza delle fondamentali norme di tolleranza, rispetto e collaborazione che, sole, possono garantire la quotidiana convivenza. 
            Nell'ambito di tale norma generale, lo studente deve osservare le disposizioni di cui ai successivi commi.
          </p>
          <ol type="a">
            <li>Lo studente è tenuto a:
              <ul>
                <li>- utilizzare tutti gli spazi e le attrezzature (in particolare gli spazi e le dotazioni comuni) nel rispetto e   d'intesa con gli altri inquilini</li>
                <li>- organizzare la propria convivenza all'interno dell'appartamento in modo condiviso da tutti gli inquilini</li>
                <li>- controllare periodicamente l'efficienza delle attrezzature e degli arredi messi a disposizione, nonché degli impianti (con particolare riguardo a tubazioni e rubinetti di acqua) provvedendo a dare tempestiva segnalazione al personale preposto degli eventuali guasti e/o malfunzionamenti</li>
                <li>- in ogni alloggio dovrà essere garantito l'ordine e la pulizia. Si dovrà inoltre consentire al personale incaricato l'esecuzione dei necessari interventi di manutenzione ordinaria e straordinaria ed il libero accesso nei casi sopra indicati</li>
                <li>- garantire al personale preposto la possibilità di effettuare controlli e visite periodiche che saranno comunicate con largo anticipo.</li>
              </ul>
            </li> <br> <br>
            
            <h2 style="text-align:center;"><b>È assolutamente vietato</b></h2>
            <li>Tenere animali di qualsiasi specie e razza nei locali del residence e nelle aree esterne ad esso annesse.</li>
            <li>Arrecare disturbo ai coinquilini; in particolare, dopo le ore 22,00 e prima delle ore 8,00 e tra le ore 14,00 e le ore 16,00, sono vietati suoni, canti e rumori di qualunque tipo che arrechino disturbo alla tranquillità e al riposo; nelle restanti ore della giornata l'utilizzazione di strumenti musicali, apparecchi radio, televisori, impianti stereofonici, ecc. ed il comportamento generale devono essere tali da non arrecare disturbo alcuno.</li>
            <li>Tenere sia nelle stanze che negli spazi comuni, armi e sostanze stupefacenti nonché materiali infiammabili, sostanze nocive negli ambienti e spazi della residenza.</li>
            <li>Collocare materiale di qualsiasi tipo negli spazi condominiali comuni.</li>
            <li>Gettare o depositare immondizia o rifiuti fuori dagli appositi contenitori e dai locali a questo adibiti.</li>
            <li>Collocare sui terrazzi e sui davanzali qualsiasi oggetto la cui presenza possa costituire pericolo per l'incolumità dei passanti o sia vietata dai regolamenti comunali vigenti; per decoro complessivo della struttura i terrazzi e i davanzali dovranno essere tenuti sgombri da qualsiasi cosa.</li>
            <li>Gettare nei condotti di scarico di lavabi, latrine, ecc., materiali che possano otturare le tubazioni.</li>
            <li>Attuare modificazioni o adattamenti nei locali, verniciature, tinteggiature, spostamenti o adattamenti di impianti, installare mensole.</li>
            <li>Manomettere i termostati. </li>
            <li>Utilizzare stufe o fornelli di qualsiasi tipo se non quelli in dotazione.</li>
            <li>Spostare dalla loro posizione (camere, spazi comuni, ecc.) qualsiasi tipo di arredo.</li>
          </ol>
          <br><br>
          <li><b>ASSEGNAZIONE DEL POSTO ALLOGGIO</b></li>
          <ol type="a">
            <li>Il posto alloggio è riservato esclusivamente allo studente assegnatario, il quale non può cederne l'uso ad estranei, nemmeno temporaneamente.</li>
            <li>E' pertanto fatto esplicito divieto:</li>
            <ul>
              <li>- di sublocare, anche temporaneamente, il posto alloggio assegnato o altro momentaneamente vuoto;</li>
              <li>- ospitare estranei con modalità diverse da quelle previste dall'art. 8 e 9 del presente Regolamento.</li>
              <li>- Il mancato rispetto dei commi 1 e 2 del presente articolo comporta l'immediata revoca del posto alloggio così come previsto al successivo art. 14.</li>
            </ul>
          </ol>
          <br><br>
          <li><b>PRESA IN CONSEGNA DELL’ALLOGGIO</b></li>
          <ol type="a">
            <li>Lo studente al momento dell'arrivo deve sottoscrivere: contratto di ospitalità, apposito verbale di consegna chiavi dell'alloggio, autorizzazioni necessarie e inventario fornito (previa verifica di quanto riportato nello stesso).</li>
            <li>Tutte le attrezzature, impianti, arredi ecc., che costituiscono la dotazione dell'appartamento assegnato sono affidati in solido agli studenti assegnatari per tutto il periodo di assegnazione dell'alloggio.</li>
          </ol>
          <br><br>
          <li><b>DOCUMENTAZIONE RICHIESTA</b></li>
          <ol type="a">
            <li>Al momento dell'assegnazione del posto alloggio, lo studente deve presentare al personale addetto un valido documento di riconoscimento (carta d'identità, passaporto, patente, permesso di soggiorno se in possesso).</li>
            <li>Ciascuno studente dovrà inoltre sottoscrivere:</li>
            <ul>
              <li>- atto di accettazione del posto alloggio, comprensivo del presente Regolamento Generale, che ne costituisce parte integrante</li>
              <li>- verbale di consegna delle chiavi</li>
              <li>- inventario</li>
            </ul>
            <li>In caso di inadempimento nella presentazione della documentazione richiesta lo studente perde il diritto all'assegnazione del posto alloggio.</li>
          </ol>
          <br><br>
          <li><b>DEPOSITO CAUZIONALE</b></li>
          <ol type="a">
            <li>Lo studente, dovrà esibire copia del deposito cauzionale alla Direzione del Residence rimborsabile dopo 90 giorni dalla restituzione delle chiavi del posto assegnato (nel caso di assenza di danni od ammanchi) e dopo 120 giorni nel caso vengano comunicati danni o ammanchi. Il rimborso della cauzione è condizionato alla verifica di eventuali danneggiamenti o ammanchi addebitabili allo studente ed accertati in un apposito sopralluogo congiunto. </li>
          </ol>
          <br><br>
          <li><b>DANNEGGIAMENTI O AMMANCHI</b></li>
          <ol type="a">
            <li>Per gli eventuali danni ed ammanchi, l'entità da corrispondere sarà pari ai costi sostenuti (materiale, manodopera e spese segretariali) per la riparazione del bene danneggiato o l'acquisto in sostituzione del bene danneggiato, ove non sia possibile procedere alla riparazione di quest' ultimo. Inoltre in caso di dolo e/o negligenza grave, verrà comminata una sanzione pari al 10% del valore del bene (in aggiunta alla sostituzione del bene danneggiato).</li>
            <li>Nel caso in cui non sia possibile imputare responsabilità individuali, ciascuno studente sarà responsabile in solido con gli altri assegnatari dell'appartamento (o del residence nel suo complesso) dei danni relativi agli spazi interni allo stesso appartamento o spazi comuni del residence.</li>
            <li>L'importo del danno sarà trattenuto dalla cauzione. Qualora questa sia insufficiente, lo studente dovrà rifondere il medesimo entro dieci giorni dalla sua notifica attraverso pagamento presso gli uffici preposti.</li>
            <li>In caso di ritardato o mancato pagamento, all'assegnatario verrà inviata la notifica di richiamo di cui all'art. 13 del presente Regolamento.</li>
            <li>Qualora sia necessario procedere ad interventi straordinari di tinteggiatura, sia nelle stanze che negli spazi comuni, dovuti a danni provocati dagli assegnatari, questi sono chiamati a sostenerne le spese.</li>
          </ol>
          <br><br>
          <li><b>ONERI DELLA DIREZIONE</b></li>
          <ol type="a">
            <li>Relativamente alla conduzione dell'alloggio è a carico della Direzione, ad eccezione di dolo e/o uso improprio:</li>
            <ul>
              <li>- la direzione degli alloggi</li>
              <li>- la custodia degli alloggi</li>
              <li>- il servizio di accoglienza in Piazza Garibaldi, 26 - Colorno</li>
              <li>- la manutenzione straordinaria degli immobili</li>
              <li>- la manutenzione ordinaria, quando prevista nel contratto, fatta eccezione di quanto è imputabile a dolo e/o uso improprio</li>
              <li>- la riparazione delle apparecchiature elettriche in dotazione eccetto il caso di dolo e/o uso improprio</li>
              <li>- pulizia delle parti comuni, scale, corridoi, sala studio qualora presente.</li>
              <li>- pagamento utenze (spese condominiali, luce, acqua, gas, tassa smaltimento rifiuti e wi-fi)</li>
              <li>- la sostituzione dei beni usurati da vetustà.</li>
            </ul>
          </ol>
          <br><br>
          <li><b>ACCESSO DI PERSONE NON ASSEGNATE</b></li>
          <ol type="a">
            <li>Allo studente non è consentito far entrare nel residence persone estranee, in qualità di visitatori, senza la preventiva autorizzazione della Direzione e comunque la presenza di visitatori non deve recare disturbo agli altri assegnatari.</li>
            <li>Lo studente sarà responsabile dei danni arrecati dagli estranei di cui al precedente comma e risponderà delle inosservanze al presente Regolamento.</li>
          </ol>
          <br><br>
          <li><b>OSPITALITA’</b></li>
          <ol type="a">
            <li>E' fatto assoluto divieto di introdurre o far permanere nel residence persone estranee dalle ore 23.00 alle ore 8.00.</li>
            <li>Tutti i casi di ospitalità non autorizzata dalla Direzione verranno considerati atti di sublocazione.</li>
          </ol>
          <br><br>
          <li><b>ACCESSO DEL PERSONALE DEL RESIDENCE</b></li>
          <ol type="a">
            <li>La Direzione è in possesso di copia delle chiavi di accesso agli alloggi.</li>
            <li>L'accesso all'alloggio da parte delle persone preposte alla gestione, ha luogo:</li>
            <ul>
              <li>- nel caso dei periodici controlli che si effettuano durante il periodo di assegnazione</li>
              <li>- dietro richiesta degli assegnatari stessi o di almeno uno di essi</li>
              <li>- anche in assenza degli assegnatari</li>
              <li>- per attuare sopralluoghi di verifica delle condizioni dell'alloggio nell'ambito dei controlli finalizzati alla manutenzione</li>
              <li>- per effettuare controlli od interventi straordinari relativi agli impianti esistenti nell'alloggio</li>
              <li>- per effettuare controlli sull'andamento generale della gestione</li>
              <li>- per eseguire interventi o riparazioni con carattere di urgenza</li>
              <li>- nel caso di prolungata assenza dell'assegnatario o nel caso il posto alloggio risulti indebitamente occupato da persone estranee.</li>
            </ul>
          </ol>
          <br><br>
          <li><b>DURATA E DISPONIBILITA’ DELL’ALLOGGIO</b></li>
          <ol type="a">
            <li>L'attività del Residence è da considerarsi quella indicata nel contratto d’iscrizione e relativo al periodo di permanenza a Colorno.</li>
          </ol>
          <br><br>
          <li><b>RILASCIO DELL’ALLOGGIO</b></li>
          <ol type="a">
            <li>Lo studente deve lasciare il posto alloggio secondo le indicazioni della Direzione.</li>
            <li>Al momento dell'effettivo rilascio, lo studente dovrà restituire le chiavi. In caso di mancata restituzione delle chiavi, verrà addebitata la spesa di duplicazione e il cambio della serratura (spese addebitate per smarrimento chiavi e sostituzione serratura € 150,00).</li>
            <li>Nel caso in cui non si ottemperi agli adempimenti richiesti dai precedenti commi, verrà trattenuto l'intero importo del deposito cauzionale versato al momento della prenotazione.</li>
          </ol>
          <br><br>
          <li><b>CONTESTAZIONI - RICHIAMI</b></li>
          <ol type="a">
            <li>Allo studente assegnatario che contravviene alle norme contenute nel presente Regolamento viene notificata dalla Direzione una contestazione scritta.</li>
            <li>L'interessato, entro sette giorni dalla notifica, può presentare proprie contro deduzioni alla Direzione.</li>
            <li>Se le contro deduzioni presentate consentono di valutare come insussistenti le infrazioni regolamentari contestate, nessun ulteriore provvedimento sanzionatorio verrà assunto. In caso contrario, il richiamo verrà notificato, con provvedimento motivato, tramite raccomandata o analoga forma di comunicazione.</li>
          </ol>
          <br><br>
          <li><b><u>REVOCA DEL POSTO ALLOGGIO</u></b></li>
          <ol type="a">
            <li>Il posto alloggio viene revocato d'ufficio nei seguenti casi:</li>
            <ul>
              <li>- omesso pagamento della retta secondo le modalità previste dal contratto di soggiorno</li>
              <li>- cessione in uso ad estranei del proprio posto alloggio oppure di altro posto alloggio momentaneamente vuoto, anche solo temporaneamente e gratuitamente</li>
              <li>- cessione del proprio posto alloggio o di altro posto alloggio in sublocazione</li>      
              <li>- ospitalità non autorizzata di estranei nelle stanze e/o appartamento</li>
              <li>- al secondo richiamo scritto di cui al precedente art. 13 nel corso della permanenza</li>   
              <li>- detenzione di armi o di sostanze stupefacenti, anche per uso personale, nell'alloggio o nei locali comuni o per comportamenti contrari all'ordine pubblico.</li> 
            </ul>
          </ol>
        </ol>
      </div>
      <div class="col-11 mx-auto" v-else>
        <ol type="1">
          <li><b>RULES OF BEHAVIOR IN A COMMUNITY</b></li>
          <h3>When living in a community, each person should behave towards the other residents and the staff with tolerance, respect and collaboration in order to help daily cohabitation. As per the above-mentioned norm, each student must stick to the following rules. 
          </h3>
          <h3>Each student must:</h3>
          <ul>
            <li>- Use every area and equipment (especially common areas and equipment) with respect and in agreement with other fellow tenants;</li>
            <li>- Organize cohabitation inside the apartment in agreement with other flat mate;</li>
            <li>- Check the efficiency of equipment and furniture and systems (such as taps or pipes) available and inform the staff promptly in case of breakdown or malfunction; </li>
            <li>- Keep the apartment in good order and clean. </li>
            <li>- Recycle waste using the recycling bins provided and follow the assigned calendar for waste collection.</li>
            <li>- Students must allow the staff access the apartment and carry out ordinary and extraordinary maintenance services when needed;</li>
            <li>- Allow staff to enter the apartment for periodic visits, which will be communicated in advance.</li>
            <li>- Inform ALMA staff of any need for maintenance by writing to: <a href="mailto:almahousing@scuolacucina.it">almahousing@scuolacucina.it</a>. In case damages or losses were not reported promptly, any maintenance costs may be charged to the student.</li>
          </ul>
            <br><br>
            <h3></h3>
            <h6><b>It is forbidden to:</b></h6>
            <h3>- Smoke inside the ALMA housing residences</h3>
            <h3>- Keep animals of any kind inside the student residence and in the outdoor areas attached;</h3>
            <h3>- Disturb other fellow tenants; in particular, from 10.00 pm until 8.00 am and between 2.00 and 4.00 pm it is forbidden to make noises and sounds that could disturb tranquility and rest; during the rest of the day the use of musical instruments, radios, TVs and stereo, etc... and general behavior should not cause a disturbance;</h3>
            <h3>- Keep inside the apartment or in common areas guns, drugs and inflammable substances, substances that can be noxious for the spaces of the residence;</h3>
            <h3>- Position any kind of material in residence common areas;</h3>
            <h3>- Throw away or drop off garbage outside of dedicated containers and of the dedicated area;</h3>
            <h3>- Position on terraces and on window sills any objects that could be dangerous for pedestrian or that are forbidden by town regulations; to guarantee the decorum of the residence, terraces and window stills should be kept empty.</h3>
            <h3>- Throw down drainage pipes or toilets any material that could block the pipes.</h3>
            <h3>- Modify or adapt the rooms, paint walls, move or adapt systems or install shelves</h3>
            <h3>- Tamper with thermostats</h3>
            <h3>- Use stoves or heaters of any kind other than the equipment available</h3>
            <h3>- Move from original position (rooms, common areas, etc.) any kind of furniture.</h3>
          <br><br>
          <li><b>ROOM ALLOCATION</b></li>
          <h3>- The accommodation is dedicated exclusively to the assignee student, who cannot entrust its use to any strangers, not even temporary.</h3>
          <h3>- It is therefore forbidden to:</h3>
          <h3>- Sublease, even if only temporarily, the assigned bed or any other bed momentarily empty.</h3>
          <h3>- Host strangers unless as indicated in section 8 and 9 of this document.</h3>
          <h3>- In case of lack of respect for this section, the assigned accommodation will be revoked as per Section 14.</h3>
          <br><br>
          <li><b>TAKING POSSESSION OF THE APARTMENT</b></li>
          <h3>- At the arrival, students must subscribe: hospitality agreement, keys delivery report, authorization and inventory (if provided)</h3>
          <h3>- All equipment, systems, furniture etc. of the assigned apartment are entrusted to all students assigned to the same apartment for the whole period of assignment. </h3>
          <br><br>
          <li><b>DOCUMENTATION REQUIRED</b></li>
          <h3>- For the assignment, students must provide the staff with a valid document, e.g. ID, passport, driving license, permit of stay (if required)</h3>
          <h3>- Each student must subscribe:</h3>
          <h3>- hospitality agreement, inclusive of this regulation</h3>
          <h3>- keys delivery report, authorization</h3>
          <h3>- inventory (if provided)</h3>
          <h3>- In case documents are not presented as required, students will lose the right to be assigned with accommodation.</h3>
          <br><br>
          <li><b>DEPOSIT</b></li>
          <h3>A deposit of 200€ is required at the arrival in ALMA. The deposit will be refunded in cash at the end of the residential phase, in case the student is not held responsible for any damages or missing items.</h3>
          <br><br>
          <li><b>DAMAGES AND MISSING ITEMS</b></li>
          <h3>- In case of damages or missing items, the student will have to cover the costs of repairing (material, labour and administration fees) or the purchase of the damaged goods. In case of fraud or gross negligence, students will have to cover an additional sanction of 10% of the value of the damaged good.</h3>
          <h3>- In case it is not possible to attribute the responsibility of the damage of a single person, all students of the same apartment (or of the same residence) will be considered responsible and will have to cover the costs of the damages in the apartment (or in the residences common areas).</h3>
          <h3>- The amount of the damage will be withheld from the deposit. In case the deposit is not sufficient, the student will have to complete the payment within 10 days from notification.</h3>
          <h3>- In case of delayed or non-payment, the student will be notified accordingly to section 13 of this document. </h3>
          <h3>- In case it were necessary to proceed with extraordinary painting, cleaning or door lock replacement, both in the rooms and in common areas, because of damages caused by the assignees, they will be asked to cover all expenses.</h3>
          <br><br>
          <li><b>MANAGEMENT’S OBLIGATIONS</b></li>
          <h3>- With references to the management of the residences, the following activities are at the expenses of the Management, with the exception of fraud and/or improper use:</h3>
          <h3>- The management of the residences</h3>
          <h3>- The safe-keeping of the residences</h3>
          <h3>- The welcome service in Piazza Garibaldi, 26 – Colorno</h3>
          <h3>- Extraordinary maintenance work of the buildings</h3>
          <h3>- Ordinary maintenance work, unless in case of fraud and/or improper use</h3>
          <h3>- Fixing all electric devices available, unless in case of fraud and/or improper use</h3>
          <h3>- Cleaning of common areas, staircases, halls, study room if present.</h3>
          <h3>- Covering all consumption costs (condominium fees, electricity, water, gas, recycling and Wi-Fi) </h3>
          <h3>- Replacing worn goods.</h3>
          <br><br>
          <li><b>STRANGERS ENTERING THE STUDENT RESIDENCE</b></li>
          <h3>- Students are not allowed to let strangers in the residence, as visitors, without prior authorization from the Management. The presence of strangers should not cause a disturbance to the other fellow tenants.</h3>
          <h3>- The student will be responsible for any damages caused by his/her visitors.</h3>
          <br><br>
          <li><b>GUESTS</b></li>
          <h3>- It is forbidden to let any strangers enter the student residences from 11.00 pm until 8.00 am.</h3>
          <h3>- Any case of guests not authorized by the Management will be considered as sub tenancy.</h3>
          <br><br>
          <li><b>STAFF ENTERING THE APARTMENTS</b></li>
          <h3>- The Management has a copy of all keys of the residences.</h3>
          <h3>- Staff can access the apartments:</h3>
          <h3>- in case of periodic control</h3>
          <h3>- If requested by at least one of the assignee</h3>
          <h3>- Even if the assignee are not present</h3>
          <h3>- To check the conditions of the apartment in order to organize maintenance works</h3>
          <h3>- In case of extraordinary maintenance services on the apartment systems</h3>
          <h3>- To check the general management condition of the apartment</h3>
          <h3>- In case of emergency, to carry out repair works</h3>
          <h3>- In case of prolonged absence of the assignee or in case the bed is proven to be illegitimately occupied by strangers</h3>
          <br><br>
          <li><b>LENGTH AND AVAILABILITY OF ACCOMMODATION</b></li>
          <h3>The availability of the accommodation in the residence is indicated in the contract and is related to the residential period in Colorno.</h3>
          <br><br>
          <li><b>RELEASE OF ACCOMMODATION</b></li>
          <h3>- Students will have to leave the apartments as indicated by the Management</h3>
          <h3>- When releasing the accommodation, each student must return the keys. In case keys won’t be returned, the student will have to cover the costs for duplication and change of door lock.</h3>
          <h3>- In case students will not comply with the duties established by this regulation, the deposit will not be refunded.</h3>
          <br><br>
          <li><b>NOTIFICATIONS - REPRIMANDS</b></li>
          <h3>- If students contravene the rules of this regulation, they will receive a written notification from the Management.</h3>
          <h3>- Within seven days from notification, students can present counterclaim to the Management.</h3>
          <h3>- Following the counterclaim, if the Management considers the violations as unfounded, no further actions will be taken. Otherwise, the Management will give further written notice.</h3>
          <br><br>
          <li><b>IMMEDIATE REVOCATION OF ACCOMMODATION</b></li>
          <h3>- Accommodation will be revoked automatically in the following cases: </h3>
          <h3>- The student did not complete payment as per the accommodation agreement</h3>
          <h3>- The student leaves, even if only temporarily and for free, the assigned bed or any other bed momentarily empty</h3>
          <h3>- The student subleases the assigned bed or any other bed</h3>
          <h3>- In case of guests not authorized by the Management in the apartment</h3>
          <h3>- In case of 2 written notifications as per Section 13</h3>
          <h3>- In case of possession of guns or drugs, even if for personal use, in the apartment or in the residence common areas, or in case of misbehaviors.</h3>
        </ol>
      </div>
    </div>
    
    <footerline></footerline>

  </div>
</template>

<script>
  // @ is an alias to /src
  import navbar from '@/components/navbar.vue'
  import herobig from '@/components/herobig.vue'

  export default {
    name: 'Home',
    components: {
      navbar,
      herobig,
    },
    data: function () {
      return {
      };
    },
  }
</script>
