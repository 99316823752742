import Vue                   from 'vue'
import VueRouter             from 'vue-router'
import Home                  from '../views/Home.vue'
import Info                  from '../views/Info.vue'
import Candidati             from '../views/Candidati.vue'
import MyAlmaLife            from '../views/MyAlmaLife.vue'
import Assistenza            from '../views/Assistenza.vue'
import Profilo               from '../views/Profilo.vue'
import Recovery              from '../views/Recovery.vue'
import Candidato             from '../views/Candidato.vue'
import Candidatura           from '../views/Candidatura.vue'
import Storico               from '../views/Storico.vue'
import Housing               from '../views/Housing.vue'
import Studente              from '../views/Studente.vue'
import Utility               from '../views/Utility.vue'
import logintest             from '../views/logintest.vue'
import Ricette               from '../views/Ricette.vue'
import Facilitazioni         from '../views/Facilitazioni.vue'
import Didattica             from '../views/Didattica.vue'
import ExtraDidattica        from '../views/ExtraDidattica.vue'
import Diplomati             from '../views/Diplomati.vue'
import elearning             from '../views/elearning.vue'
import Tesi                  from '../views/Tesi.vue'
import DayFirst              from '../views/DayFirst.vue'
import Regolamento           from '../views/Regolamento.vue'
import Interventi            from '../views/Interventi.vue'
import RegolamentoHousing    from '../views/RegolamentoHousing.vue'
import Corsi                 from '../views/Corsi.vue'
import Chiediassistenza      from '../views/Chiediassistenza.vue'
import RegolamentoAlma       from '../views/RegolamentoAlma.vue'
import InvioEmailRecovery    from '../views/Invioemailrecovery.vue'
import DescrizioniAlloggio   from '../views/DescrizioniAlloggio.vue'
import HousingRifiuti        from '../views/HousingRifiuti.vue'
import RegolamentoHousingGen from '../views/RegolamentoHousingGen.vue'
import Voucher               from '../views/Voucher.vue'
import Prenotazioni          from '../views/Prenotazioni.vue'
import EcommerceHome         from '../views/EcommerceHome.vue'
import EcommerceShop         from '../views/EcommerceShop.vue'
import EcommerceRiepilogo    from '../views/EcommerceRiepilogo.vue'
import EcommerceBadge        from '../views/EcommerceBadge.vue'
import EcommerceTYP          from '../views/EcommerceTYP.vue'
import EcommerceStorico      from '../views/EcommerceStorico.vue'
import AreaSponsor           from '../views/AreaSponsor.vue'
import Presenze              from '../views/Presenze.vue'
import Esami                 from '../views/Esami.vue'


Vue.use(VueRouter)

const routes = [
    { path: '/',                         name: 'Home',                  component: Home },
    { path: '/info',                     name: 'Info',                  component: Info },
    { path: '/candidati',                name: 'Candidati',             component: Candidati },
    { path: '/myalmalife',               name: 'MyAlmaLife',            component: MyAlmaLife },
    { path: '/dayfirst',                 name: 'DayFirst',              component: DayFirst },
    { path: '/regolamento',              name: 'Regolamento',           component: Regolamento },
    { path: '/corsi',                    name: 'Corsi',                 component: Corsi },
    { path: '/regolamentoHousing',       name: 'RegolamentoHousing',    component: RegolamentoHousing },
    { path: '/regolamentoHousingGen',    name: 'RegolamentoHousingGen', component: RegolamentoHousingGen },
    { path: '/assistenza',               name: 'Assistenza',            component: Assistenza },
    { path: '/profilo',                  name: 'Profilo',               component: Profilo },
    { path: '/recovery',                 name: 'Recovery',              component: Recovery },
    { path: '/areacandidato',            name: 'Candidato',             component: Candidato },
    { path: '/candidatura/:productcode', name: 'Candidatura',           component: Candidatura, props: true },
    { path: '/candidatura/:productcode/:page', name: 'Candidatura',           component: Candidatura, props: true },
    { path: '/storico',                  name: 'Storico',               component: Storico },
    { path: '/ricette',                  name: 'Ricette',               component: Ricette },
    { path: '/didattica',                name: 'Didattica',             component: Didattica },
    { path: '/extradidattica',           name: 'ExtraDidattica',        component: ExtraDidattica },
    { path: '/diplomati',                name: 'Diplomati',             component: Diplomati },
    { path: '/facilitazioni',            name: 'Facilitazioni',         component: Facilitazioni },
    { path: '/housing',                  name: 'Housing',               component: Housing },
    { path: '/studente',                 name: 'Studente',              component: Studente },
    { path: '/utility',                  name: 'Utility',               component: Utility },
    { path: '/logintest',                name: 'logintest',             component: logintest },
    { path: '/elearning',                name: 'elearning',             component: elearning },
    { path: '/tesi',                     name: 'Tesi',                  component: Tesi },
    { path: '/interventi',               name: 'Interventi',            component: Interventi, props: true },
    { path: '/chiediassistenza',         name: 'Chiediassistenza',      component: Chiediassistenza },
    { path: '/regolamentoalma',          name: 'RegolamentoAlma',       component: RegolamentoAlma },
    { path: '/ierecovery',               name: 'InvioEmailRecovery',    component: InvioEmailRecovery },
    { path: '/descralloggio',            name: 'descrizioneAlloggio',   component: DescrizioniAlloggio },
    { path: '/waste',                    name: 'HousingRifiuti',        component: HousingRifiuti },
    { path: '/voucher',                  name: 'Voucher',               component: Voucher },
    { path: '/prenotazioni',             name: 'Prenotazioni',          component: Prenotazioni },
    { path: '/ecommerce',                name: 'EcommerceHome',         component: EcommerceHome },
    { path: '/ecommerce/shop',           name: 'EcommerceShop',         component: EcommerceShop },
    { path: '/ecommerce/riepilogo',      name: 'EcommerceRiepilogo',    component: EcommerceRiepilogo, props: true },
    { path: '/ecommerce/topup',          name: 'EcommerceBadge',        component: EcommerceBadge },
    { path: '/ecommerce/typ/:success',   name: 'EcommerceTYP',          component: EcommerceTYP, props: true },
    { path: '/ecommerce/log',            name: 'EcommerceStorico',      component: EcommerceStorico },
    { path: '/sponsor',                  name: 'AreaSponsor',           component: AreaSponsor },
    { path: '/presenze',                 name: 'Presenze',              component: Presenze },
    { path: '/esami',                    name: 'Esami',                 component: Esami }
]

const router = new VueRouter({routes})

export default router