<template>
  <div class="home">
    <navbar v-bind:menulist="$store.state.mainmenu" ></navbar>
    <herobig v-bind:title="$t('title')"></herobig>

    <h2 class="welcome">{{$t('subtitleCorsi')}}</h2>

    <boxcontainer :key="$store.state.lingua">

        <div class="row">

          <div v-bind:class="[' mx-auto col-lg-'+box.w]" v-for="box in boxes" v-bind:key="box.id">
            <box 
              v-bind:imgname="box.imgname" 
              v-bind:alttext="$t('boxesCorsi'+box.id+'.alttext')"
              v-bind:title="$t('boxesCorsi'+box.id+'.title')" 
              v-bind:menulist="box.menulist"
              v-bind:item="box.item"
              v-bind:extlink="$t('boxesCorsi'+box.id+'.extlink')"
              >
            </box>
          </div>

        </div>

    </boxcontainer>
    <footerline></footerline>

  </div>
</template>

<script>
  // @ is an alias to /src
  import navbar from '@/components/navbar.vue'
  import herobig from '@/components/herobig.vue'
  import box from '@/components/box.vue'
  import boxcontainer from '@/components/boxcontainer.vue'
  import footerline from '@/components/footerline.vue'

  export default {
    name: 'Home',
    components: {
      navbar,
      herobig,
      box,
      boxcontainer,
      footerline
    },
    data: function () {
      return {
        boxes: []
      };
    },
    computed: {
      linguaxxx: function() {
        return this.$store.state.lingua;
      }
    },
    watch: {
      linguaxxx: function() {
        this.fillBoxes()
        console.log('lingua che cambia!!!');
      }
    },
    mounted: function() {
      this.fillBoxes()
    },
    methods: {
      fillBoxes: function() {
        var w=12/4;
        this.boxes.splice(0,this.boxes.length);
        this.boxes.push( { 
          id: 1,
          w: w,
          imgname: 'img/icorsi.jpg'
        } );
        this.boxes.push( { 
          id: 2,
          w: w,
          imgname: 'img/openday.jpg'
        } );
        this.boxes.push( { 
          id: 3,
          w: w,
          imgname: 'img/richiediinfo.jpg'
        } );
        console.log('corsi lingua',this.$store.state.lingua)
        if(this.$store.state.lingua == 'it') {
          this.boxes.push( { 
            id: 4,
            w: w,
            imgname: 'img/segreteria.jpg'
          } );
        }
      }
    }
  }
</script>