<template>
  <div class="home">
    <navbar v-bind:menulist="$store.state.mainmenu" ></navbar>
    <herobig v-bind:title="$t('title')"></herobig>

    <h2 class="welcome">{{$t('subtitleExtraDidattica')}}</h2>

    <boxcontainer>

        <div class="row">

          <div v-bind:class="[' mx-auto col-lg-'+box.w]" v-for="box in boxes" v-bind:key="box.id">
            <box 
              v-bind:imgname="box.imgname" 
              v-bind:alttext="$t('boxesExtraDidattica'+box.id+'.alttext')"
              v-bind:title="$t('boxesExtraDidattica'+box.id+'.title')" 
              v-bind:menulist="box.menulist"
              v-bind:item="box.item"
              v-bind:extlink="box.extlink"
              >
            </box>
          </div>

        </div>

    </boxcontainer>
    <footerline></footerline>

  </div>
</template>

<script>
  // @ is an alias to /src
  import navbar from '@/components/navbar.vue'
  import herobig from '@/components/herobig.vue'
  import box from '@/components/box.vue'
  import boxcontainer from '@/components/boxcontainer.vue'
  import footerline from '@/components/footerline.vue'

  export default {
    name: 'Home',
    components: {
      navbar,
      herobig,
      box,
      boxcontainer,
      footerline
    },
    data: function () {
      return {
        boxes: []
      };
    },
    mounted: function() {
      var w=12/3;
      this.boxes.splice(0,this.boxes.length);
      this.boxes.push( { 
        id: 1,
        w: w,
        imgname: 'img/candidatura.jpg',
//        menulist: ['Building 1','Apartment 2','Via della salute, 33','41220 Parma'],
//        item: this.$store.state.mainmenu[1],
      } );
      this.boxes.push( { 
        id: 2,
        w: w,
        imgname: 'img/candidatura.jpg',
//        menulist: ['Stato','Documenti','Pagamenti'],
//        item: this.$store.state.mainmenu[1]
//        extlink: 'https://www.alma.scuolacucina.it/come-iscriversi/'
      } );
      this.boxes.push( { 
        id: 3,
        w: w,
        imgname: 'img/candidatura.jpg',
//        menulist: ['Stato','Documenti','Pagamenti'],
//        item: this.$store.state.mainmenu[1]
//        extlink: 'https://www.alma.scuolacucina.it/eventi/'
      } );
    }
  }
</script>