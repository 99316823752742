<template>

  <div class="row">
    <div class="col">

      <div class="row">
        <div class="col-5 offset-1">
          <div class="form-group ">
            <label class="control-label">USERNAME</label>
            <input v-model="username" maxlength="100" type="text" class="form-control" :placeholder="$t('Inserisciun')" />
          </div>
        </div>
        <div class="col-5">
          <div class="form-group ">
            <label class="control-label">PASSWORD</label>
            <div class="input-group">
              <input v-model="password" maxlength="100" v-bind:type="reveal?'text':'password'" class="form-control" placeholder="Password"/>
              <div class="input-group-append" v-on:click="chgReveal">
                <span class="input-group-text">
                  <span v-if="!reveal" class="oi oi-eye"></span>
                  <span v-if="reveal" class="oi oi-code"></span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-5 offset-1">
          <div class="form-group">
            <span v-on:click.prevent="$emit('signup')" v-if="this.$i18n.locale=='it'">Se desideri registrarti, <u>premi qui</u> <br/></span>
            <span v-on:click.prevent="$emit('signup')" v-else>If you want to register, <u>click here</u> <br/></span>

            <span v-on:click.prevent="goto" v-if="this.$i18n.locale=='it'">Password dimenticata? <u>premi qui</u></span>
            <span v-on:click.prevent="goto" v-else>Password forgotten? <u>click here</u></span>
          </div>
        </div>
        <div class="col-5">
          <span v-if="showerror && this.$i18n.locale=='it'" class="text-danger">Credenziali errate</span>
          <span v-else-if="showerror && this.$i18n.locale=='en'" class="text-danger">Wrong credentials</span>

          <span v-if="showblocked && this.$i18n.locale=='it'" class="text-danger">Utente Bloccato: <br> Contattare la Segreteria Studenti o scrivere una mail a <a href="mailto:segreteria@scuolacucina.it" style="font-size:18px;padding-left:5px;">segreteria@scuolacucina.it</a></span>
          <span v-else-if="showblocked && this.$i18n.locale=='en'" class="text-danger">Blocked User: <br> Contact the Student Secreteriat or send an email at <a href="mailto:segreteria@scuolacucina.it" style="font-size:18px;padding-left:5px;">segreteria@scuolacucina.it</a></span>
          <button type="submit" class="btn btn-primary float-right" :disabled="btnDoLoginDisabled ? true : false" v-on:click.prevent="doLogin">{{$t('Invia')}}</button>
        </div>
      </div>

    </div>
  </div>


</template>

<script>
  export default {
    name: 'singin',
    props: {},
    data: function () {
      return {
        username: '',
        password: '',
        show: false,
        showerror: false,
        showblocked: false,
        reveal: false,
        btnDoLoginDisabled: false,
      }
    },
    methods: {
      chgReveal: function(){
        this.reveal = !this.reveal;
      },
      doLogin: function() {
        var self=this;
        self.btnDoLoginDisabled = true
        var data={ username: this.username, password: this.password };
        self.showerror=false;
        self.showblocked=false;
        if(this.username != '' && this.password != '') {
          this.$store.dispatch('login', data)
          .then(function() {
            if (self.$store.state.connection.userdata && self.$store.state.blocked === false) {
              var data2={ username: self.$store.state.connection.username, crypt: self.$store.state.connection.userdata };
              self.$store.dispatch('getProfile', data2).then(function() {
                if (self.$store.state.userprofile.Lingua__c) {
                  console.log('set personal language to:'+self.$store.state.userprofile.Lingua__c)
                  self.$i18n.locale = self.$store.state.userprofile.Lingua__c;
                } else  {
                  self.$i18n.locale = 'it'; // default to it
                }
                console.log('profile recoveder');
                self.$emit('logged');
              });
            } else if(!self.$store.state.connection.userdata && self.$store.state.blocked !== true) {
              self.showerror=true;
            } else if(self.$store.state.blocked === true){
              self.showblocked=true;
            } 
            self.btnDoLoginDisabled = false
          })
        } else {
          self.showerror = true
          self.btnDoLoginDisabled = false
        }
      },
      goto: function() {
        this.$router.push({ name: 'InvioEmailRecovery' });
      }
    }
  }
</script>

<style scoped>
</style>