<template>
  <div class="home">
    <navbar v-bind:menulist="$store.state.mainmenu"></navbar>
    <herobig v-bind:title="$t('title')"></herobig>
    <boxcontainer>
      <div class="row mx-auto">
          <div class="col">
          </div>
          <div class="col" style="text-align:center">
            <label class="control-label">{{$t('NuovaPassword')}}</label>
            <input
            v-model="pwd"
            maxlength="100"
            type="password"
            class="form-control"
            placeholder="Nuova Password"
            />

            <label class="control-label">{{$t('ConfermaPassword')}}</label>
            <input
            v-model="pwdconfirm"
            maxlength="100"
            type="password"
            class="form-control"
            placeholder="Conferma Password"
            />
            <button type="submit" class="btn btn-primary" style="margin: 15px" v-on:click.prevent="savepwd">{{$t('Salva')}}</button>
            <div style="margin-top: 15px">
                <span v-if="response == 'SuccessRequest'" class="text-success">{{$t(response)}}</span>
                <span v-else class="text-danger">{{$t(response)}}</span>
            </div>
          </div>
          <div class="col">
          </div>
        </div>
    </boxcontainer>

    <footerline></footerline>
  </div>
</template>

<script>
// @ is an alias to /src
import navbar from "@/components/navbar.vue";
import herobig from "@/components/herobig.vue";
import boxcontainer from "@/components/boxcontainer.vue";
import footerline from "@/components/footerline.vue";

export default {
  name: "Home",
  components: {
    navbar,
    herobig,
    boxcontainer,
    footerline
  },
  data: function() {
    return {
      pwd: '',
      pwdconfirm: '',
      accountId: '',
      response: ''
    };
  },
  mounted: function() {
    var siteUrl = window.location.href;
    var name = 'tkn';
    name = name.replace(/[[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    var results = regex.exec(siteUrl);
    if (!results) this.$store.state.tkn = '';
    if (!results[2]) this.$store.state.tkn = '';
    this.$store.state.tkn = decodeURIComponent(results[2].replace(/\+/g, " "));
  },
  methods: {
    savepwd: function() {
        this.$store.state.newpwd = this.pwd;
        this.$store.state.newpwdconf = this.pwdconfirm;
        var self = this;
        console.log('Nuova password(this.pwd)', this.pwd)
        this.$store.dispatch('setNewPwd')
        .then(function(resp) {
            console.log(resp);
            self.response = resp;
            if(resp == 'SuccessRequest'){
                self.$router.push({ name: 'Profilo' });
            }
        });
    }
  }
}
</script>
