<template>

  <div class="home_feat_1_box mx-auto">
    <a v-bind:href="(!disabled?'#':undefined)" v-on:click.prevent="goto()" >
      <img v-bind:src="imgname" class="img-responsive"  v-bind:class="{ 'img-responsive-disable': disabled, 'imganimation': !disabled }" v-bind:alt="alttext">
      <div class="short_info" v-bind:class="{ 'disable': disabled }">
        <h3>{{title}}</h3>
            <ul class="oro">
              <li v-for="item in menulist" v-bind:key="item">{{item}}</li>
            </ul>
          
      </div>
    </a>
  </div>

</template>

<script>
  export default {
    name: 'box',
    props: {
      title: String,
      imgname: String,
      alttext: String,
      menulist: Array,
      item: Object,
      extlink: String,
      disabled: Boolean,
      params: Object
    },
    methods: {
      goto: function() {
        if (this.disabled) return;
        if (this.item) { // internal link
          this.show=false;
          this.$router.push({ name: this.item.path, params: this.item.params });
        }
        if (this.extlink) { // external link
           console.log('external link:'+this.extlink)
          window.location=this.extlink;
        }
      }
    }
  }
</script>

<style scoped>
</style>