<template>
  <div class="home">
    <navbar v-bind:menulist="$store.state.mainmenu"></navbar>
    <herobig v-bind:title="$t('title')"></herobig>

    <h2 align="center" class="welcome">{{$t('subtitlePrimoGiorno')}}</h2>

    <div class="row" v-if="this.$i18n.locale=='it'">
      <div class="col-10 mx-auto faq">
        <div class="faq-q">
          <h2 class="titoloh2">
            <span class="oi oi-caret-right"></span> Quando mi devo presentare e dove?
          </h2>
          <p class="par">
            Corso Tecniche di Cucina, Corso Tecniche di pasticceria, Corso Manager della Ristorazione e Corso Master ALMA AIS arrivano il giorno stesso della data di inizio corso; gli altri corsi arrivano il giorno prima della data di partenza. L’orario viene comunicato a tutti appena possibile, non appena la   Direzione ha deliberato l’organizzazione dell’intera giornata didattica
          </p>
        </div>
        <div class="faq-q">
          <h2 class="titoloh2">
            <span class="oi oi-caret-right"></span> Posso portare le valigie con me o le devo lasciare in albergo/macchina?
          </h2>
          <p class="par">
            Le valigie possono essere portate in Segreteria Studenti, al momento del proprio arrivo, per poi essere trasportate in
            alloggio quando le colleghe accompagnano gli allievi a vedere l’appartamento. Se si viene in macchina, possono restare in macchina
            (non è necessario scaricarle): ALMA declina ogni responsabilità in caso di furti. Non è consigliato lasciarle in deposito
            in albergo perché non si ha il tempo di passare a prenderle a posteriori.
          </p>
        </div>

        <div class="faq-q">
          <h2 class="titoloh2">
            <span class="oi oi-caret-right"></span> Posso ricevere i libri in dotazione prima dell’inizio del corso?
          </h2>
          <p class="par">No, non è possibile. I libri vengono consegnati i primi giorni di corso.</p>
        </div>

        <div class="faq-q">
          <h2 class="titoloh2">
            <span class="oi oi-caret-right"></span> Quando ci consegnate la divisa?
          </h2>
          <p class="par">La divisa viene consegnata il giorno stesso dell’arrivo in ALMA.</p>
        </div>

        <div class="faq-q">
          <h2 class="titoloh2">
            <span class="oi oi-caret-right"></span> Se la divisa non mi va bene posso cambiarla?
          </h2>
          <p
            class="par"
          >Certo, alla consegna viene dato il tempo per provarla. Se qualche capo non dovesse andare bene viene immediatamente cambiato al momento.</p>
        </div>

        <div class="faq-q">
          <h2 class="titoloh2">
            <span class="oi oi-caret-right"></span> Posso prendere una giacca/pantalone/camicia in più?
          </h2>
          <p
            class="par"
          >Certo, è possibile ampliare la dotazione fornita acquistando ciò che si desidera presso il Caffè Italia in ALMA.</p>
          <ul>
            <li>
              <u>
                 per Manager della Ristorazione e Master ALMA AIS:
                <b>come devo vestirmi per seguire le lezioni?</b>
              </u>
            </li>
            <li>
              
              <b>
                <u>Manager della Ristorazione:</u>
              </b> l’abbigliamento richiesto è molto formale; completo scuro (nero, blu o grigio) e cravatta per gli uomini e tailleur o completo giacca pantaloni per le donne.
            </li>
            <li>
              
              <b>
                <u>Master ALMA AIS:</u>
              </b> è necessario indossare sempre la divisa ufficiale AIS; pantaloni grigi, camicia bianca e giacca blu con spilla e cravatta AIS (foulard AIS per le donne).
            </li>
          </ul>
        </div>

        <div class="faq-q">
          <h2 class="titoloh2">
            <span class="oi oi-caret-right"></span> Posso portare la mia divisa da cucina/pasticceria e il mio kit utensili personale?
          </h2>
          <p class="par">
            No, non è possibile. ALMA fornisce la dotazione necessaria per lo svolgimento delle lezioni ed è consentito l’utilizzo della sola
            divisa e kit utensili della Scuola.
          </p>
        </div>

        <div class="faq-q">
          <h2 class="titoloh2">
            <span class="oi oi-caret-right"></span> I miei genitori possono partecipare alla Cerimonia di Apertura Corso?
          </h2>
          <p
            class="par"
          >Tutte le attività sono a porte chiuse, non è quindi possibile far accedere accompagnatori.</p>
        </div>

        <div class="faq-q">
          <h2 class="titoloh2">
            <span class="oi oi-caret-right"></span> Sono un allievo del corso Tecniche di Cucina-Tecniche di Pasticceria: i miei genitori possono partecipare
            alla consegna dell’attestato finale?
          </h2>
          <p
            class="par"
          >Tutte le attività sono a porte chiuse, non è quindi possibile far accedere accompagnatori</p>
        </div>

        <div class="faq-q">
          <h2 class="titoloh2">
            <span class="oi oi-caret-right"></span> Quando posso vedere l’appartamento?
          </h2>
          <p class="par">L’appartamento viene mostrato il giorno di arrivo in ALMA.</p>
        </div>

        <div class="faq-q">
          <h2 class="titoloh2">
            <span class="oi oi-caret-right"></span> Posso entrare nell’appartamento qualche giorno prima così da sistemarmi?
          </h2>
          <p class="par">
            No, non è possibile, gli appartamenti ospitano allievi che ci vivono fino alla fine del proprio corso, e che vengono poi puliti e
            liberati per i nuovi allievi in arrivo.
          </p>
        </div>

        <div class="faq-q">
          <h2 class="titoloh2">
            <span class="oi oi-caret-right"></span> I miei genitori possono venire con me a vedere l’appartamento?
          </h2>
          <p
            class="par"
          >Certo, gli allievi possono essere accompagnati negli appartamenti, ma solo il giorno dell’arrivo.</p>
        </div>
      </div>
    </div>

    <div class="row" v-else>
      <div class="col-10 mx-auto faq">
        <div class="faq-q">
          <h2 class="titoloh2">
            <span class="oi oi-caret-right"></span> When should I arrive and where should I go?
          </h2>
          <p class="par">
            Students usually arrive in the afternoon of the day before the course start date. The exact time of themeeting is usually communicated to each student about a   month before the beginning of the course. The meeting takes place at ALMA campus.At the same time students can check in at ALMA Housing. It is not possible to enter the ALMA Housing before the meeting and it is not possible to  enter later than 6 p.m. on the day of reception.
          </p>
        </div>
        <div class="faq-q">
          <h2 class="titoloh2">
            <span class="oi oi-caret-right"></span> Which are the nearest airports and how can I reach ALMA?
          </h2>
          <p class="par">
            The nearest airports are Bologna and Milano (Bergamo, Linate and Malpensa). Most internationalflights land in Milano Malpensa. From all airports there are shuttles leaving to Parma
            <ul>
              <li>Bologna airport: 30min’ bus to central station, then 1h train to Parma station</li>
              <li>Linate: 30min’ bus to central station, then 1h30m’s train to Parma station</li>
              <li>Milano Malpensa: 2h30m’s bus to Parma station</li>
            </ul>
            From Parma station you can reach Colorno by taxi (20 min), bus (40 min) or train (10 min, connections to be checked on <u><a href="https://www.trenitalia.com/">www.trenitalia.it</a></u>). The school is located in the Ducal Palace in Colorno, address Piazza Garibaldi 26.You must inform ALMA Student Office about your flight details once you book the ticket (arrival airport and flight date and hour). ALMA can organize transfers from the airports under request at students’ charge
          </p>
        </div>

        <div class="faq-q">
          <h2 class="titoloh2">
            <span class="oi oi-caret-right"></span> Can I bring the luggage with me or should I leave it at the hotel/in the car?
          </h2>
          <p class="par">Luggage can be taken to the Student Office upon arrival and then transported to the accommodation when the staff accompanies the students to the apartments.If you come by car, it   is not necessary to unload the luggage. ALMA declines all responsibility in case of theft.We do not recommend leaving luggage in storage at the hotel because you may not have time to pick it up afterwards.</p>
        </div>

        <div class="faq-q">
          <h2 class="titoloh2">
            <span class="oi oi-caret-right"></span> When is the uniform delivered?
          </h2>
          <p class="par">The uniform is usually delivered the day after arrival, depending on the organization of the day.</p>
        </div>

        <div class="faq-q">
          <h2 class="titoloh2">
            <span class="oi oi-caret-right"></span> Can I change size, if the uniform does not fit?
          </h2>
          <p
            class="par"
          >Yes. At the moment of delivery, students have time to try on the uniform and change any item that does not </p>
        </div>

        <div class="faq-q">
          <h2 class="titoloh2">
            <span class="oi oi-caret-right"></span> Can I buy any extra jackets/pants/shirts?
          </h2>
          <p
            class="par"
          >Yes. You can integrate the uniform supplied by purchasing whatever you want at Caffè Italia in ALMA.</p>
        </div>

        <div class="faq-q">
          <h2 class="titoloh2">
            <span class="oi oi-caret-right"></span> Can I bring my own kitchen/pastry uniform and tool kit?
          </h2>
          <p class="par">
            No, it is not allowed. ALMA provides the necessary tools and equipment for the lessons. Only the ALMA uniform and tool kit are allowed.Students from partner schools may bring their tool kit while ALMA provides school uniforms.
          </p>
        </div>

        <div class="faq-q">
          <h2 class="titoloh2">
            <span class="oi oi-caret-right"></span> When can I visit the apartment?
          </h2>
          <p
            class="par"
          >You can visit the apartment on the  day of arrival at ALMA.</p>
        </div>

        <div class="faq-q">
          <h2 class="titoloh2">
            <span class="oi oi-caret-right"></span> May I enter the apartment a few days in advance in  order to settled in?
          </h2>
          <p
            class="par"
          >No, it is not possible. Students live in the apartments until the end of their course. Apartments are then cleaned and released for the arrival of new students.</p>
        </div>

        <div class="faq-q">
          <h2 class="titoloh2">
            <span class="oi oi-caret-right"></span> Which documents do I have to prepare before leaving for Italy?
          </h2>
          <p class="par">
            <ul>
              <li><u>Visa and permit of stay</u></li><br>
              <p class="par">Extra EU citizens are required a visa to attend the program for programs longer than 90 days. The student visa application process takes about 90    days, but timings may vary according to the country. The Student Office will assist applicants in the process. Within 8 days after arrival in Italy, students must apply for a student permit of stay; the Student Office will assist the students in the process and will book an appointment for the students the day after arrival. The price for document processing and assistance is 180€ (subject to updates according to Government’s prices). Students must attend 2 additional appointments at the questura di Parma to complete the permit of stay process; transfers to and from the questura are at students’ charge.NB: European citizens are not required a visa to attend the program.</p>
              <li>
                <u>Health Insurance</u>
                <ul class="elencopsquare">
                  <li>Health insurance is compulsory in Italy for extra-EU citizens for the whole lenght of their stay. You must contract a health insurance in your country of origin, make sure it is   valid in Italy, and then send a copy to the Student Office before course start.</li>
                  <li>European citizens can send their national health insurance card</li>
                </ul>
              </li>
              <li><u>Medical Anamnestic Certificate</u></li>
              <p class="par">Students must submit a specific medical certificate (medical anamnestic certificate), issued by the attending physician, certifying the  absence of existing infectious contagious pathologies that contraindicate or constitute a significant risk for  oneself or for  third parties during the performanceof work and/or living together. The medical certificate must be valid, translated into English and presented no earlier than 30 days before the beginning of the course.</p>
            </ul>
          </p>
        </div>

        <div class="faq-q">
          <h2 class="titoloh2">
            <span class="oi oi-caret-right"></span> Are the ALMA Housings furnished and equipped? What should I bring from home to live there?
          </h2>
          <p class="par">
            The ALMA Housings are fully furnished apartments, including living room, kitchen, bedrooms and restrooms, equipped with washing machines, dryers, dishwashers, kitchen utensils and equipment, heating, air-conditioning and Wi-Fi internet access. A pair of sheets and towels are provided, as    well as a light blanket.
          </p>
        </div>

        <div class="faq-q">
          <h2 class="titoloh2">
            <span class="oi oi-caret-right"></span> Are the ALMA housings located near the school?
          </h2>
          <p
            class="par"
          >Yes, the ALMA Housings are located within walking distance to the School and all the main facilities.</p>
        </div>
      </div>
    </div>

    <footerline></footerline>
  </div>
</template>

<script>
// @ is an alias to /src
import navbar from "@/components/navbar.vue";
import herobig from "@/components/herobig.vue";

export default {
  name: "Home",
  components: {
    navbar,
    herobig
  },
  data: function() {
    return {};
  }
};
</script>