<template>
  <div class="footer">
    <div class="copy">&copy; Alma 2020 - All rights reserved. <span style="color: #314361">v1.3</span></div>
  </div>
</template>

<script>
  export default {
    name: 'footerline',
    props: {
    }
  }
</script>

<style scoped>
</style>