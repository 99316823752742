<template>
  <div class="home">
    <navbar v-bind:menulist="$store.state.mainmenu" ></navbar>
    <herobig v-bind:title="$t('title')"></herobig>

    <h2 class="welcome">{{$t('subtitleCandidati')}}</h2>

    <boxcontainer>

        <div class="row">

          <div v-bind:class="['col-lg-'+box.w]" v-for="box in boxes" v-bind:key="box.id">
            <box 
              v-bind:imgname="box.imgname" 
              v-bind:alttext="$t('boxesCandidati'+box.id+'.alttext')"
              v-bind:title="$t('boxesCandidati'+box.id+'.title')" 
              v-bind:menulist="$t('boxesCandidati'+box.id+'.menulist')"
              v-bind:item="box.item"
              v-bind:disabled="box.disabled"
              v-bind:extlink="$t('boxesCandidati'+box.id+'.extlink')"
              >
            </box>
          </div>

        </div>

    </boxcontainer>
    <footerline></footerline>

  </div>
</template>

<script>
  // @ is an alias to /src
  import navbar from '@/components/navbar.vue'
  import herobig from '@/components/herobig.vue'
  import box from '@/components/box.vue'
  import boxcontainer from '@/components/boxcontainer.vue'
  import footerline from '@/components/footerline.vue'

  export default {
    name: 'Home',
    components: {
      navbar,
      herobig,
      box,
      boxcontainer,
      footerline
    },
    data: function () {
      return {
        boxes: []
      };
    },
    mounted: function() {
      var w=12/4;
      this.boxes.splice(0,this.boxes.length);
      this.boxes.push( { 
        id: 1,
        w: w,
        imgname: 'img/scegliiltuocorso.jpg'
      } );
      this.boxes.push( { 
        id: 2,
        w: w,
        disabled: !this.$store.getters.candidato,
        imgname: 'img/areacandidati.jpg',
        item: {title:'Candidato', path:'Candidato'},
      } );
      this.boxes.push( { 
        id: 3,
        w: w,
        imgname: 'img/assistenza.jpg',
        item: {title:'Chiediassistenza', path:'Chiediassistenza'},
      } );
      this.boxes.push( { 
        id: 4,
        w: w,
        disabled: !this.$store.getters.candidato,
        imgname: 'img/storicocandidatura.jpg',
        item: {title:'Storico', path:'Storico'},
      } );
    }
  }
</script>