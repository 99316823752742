<template>

  <div class="section background-image">
    <div class="wrapper">
      <div class="columns column w-row">
        <div class="column herocontent w-col w-col-6">
          <h2 class=" bianco centrato">{{$t('title')}}</h2>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  export default {
    name: 'herobig',
    props: {
      title: String,
    }
  }
</script>

<style scoped>
</style>