<template>
  <div class="home">
    <navbar v-bind:menulist="$store.state.mainmenu"></navbar>
    <herobig v-bind:title="$t('title')"></herobig>
    <u :class="{disabled: !this.$store.state.userprofile.Id}"><a href="https://www.alma.scuolacucina.it/" target="_blank"><h2 class="welcome">{{$t('Prenotazioni')}}<i class="fa fa-info-circle" style="font-size:18px"></i></h2></a></u>

    <div :class="{disabled: !this.$store.state.userprofile.Id}" class="container p-3" >
      <div class="row">
        <div class="col-12 font-weight-bold">{{$t('CosaVuoiPrenotare')}}</div>
      </div>
      <div class="row no-gutters">
        <div class="col-md-7 col-xs-11 mr-md-5 mr-xs-0">
          <div class="product-details mr-2">
            <div class="d-flex justify-content-between">
              <div class="input-group mb-1">
                <select
                  class="custom-select custom-select-sm mb-1 w-50"
                  id="inputGroupSelect01"
                  size="1"
                  v-on:change="handlechange"
                >
                  <option selected>{{$t('Seleziona')}}</option>
                  <!--option value="mensa">Mensa</option-->
                  <option value="eventi">{{$t('Eventi')}}</option>
                  <option value="corsi">{{$t('CorsiModuli')}}</option>
                  <option v-if="$store.getters.enableEsami == true" value="esami">{{$t('SessioniEsami')}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!--div v-if="mensa">
        <mensa></mensa>
      </div-->

      <div v-if="eventi">
        <eventi></eventi>
      </div>
      <div v-if="corsi">
        <corsi></corsi>
      </div>
      <div v-if="esami">
        <esami></esami>
      </div>
    </div>

    <!-- BLOCCO UNO -->
    <div class="row mx-auto"></div>
    <!-- FINE PRIMA RIGA DI PRODOTTO -->

    <div class="col text-center mt-3">
      <button class="btn btn-primary" @click="$router.push({ name: 'Interventi', params: {from: 'Segreteria'} })">{{ $t('NeedHelp') }}</button>
    </div>

    <footerline></footerline>
  </div>
</template>

<script>
// @ is an alias to /src
import navbar from "@/components/navbar.vue";
import herobig from "@/components/herobig.vue";
import footerline from "@/components/footerline.vue";
import eventi from "@/components/eventi.vue";
//import mensa from "@/components/mensa.vue";
import corsi from "@/components/corsimoduli.vue";
import esami from "@/components/esami.vue";



export default {
  name: "Home",
  components: {
    navbar,
    herobig,
    footerline,
    eventi,
    //mensa,
    corsi,
    esami
  },
  data: function() {
    return {
      boxes: [],
      box: '',
      date: new Date(),
      days: [],
      selectedDate: null
    };
  },
  mounted: function() {
    console.log('Ha esami?', this.$store.state.enableEsami)
  },
  computed: {
    eventi: function() {
      if(this.box == 'eventi') return true;
      return false;
    },
    mensa: function() {
      if(this.box == 'mensa') return true;
      return false;
    },
    corsi: function() {
      if(this.box == 'corsi') return true;
      return false;
    },
    esami: function() {
      if(this.box == 'esami') return true;
      return false;
    },
    dates() {
    return this.days.map(day => day.date);
    },
    attributes() {
      return this.dates.map(date => ({
        highlight: true,
        dates: date,
      }));
    }
  },
  methods: {
    handlechange(event){
      this.box = event.target.value;
      console.log('this.box', this.box)
    },
    onDayClick(day) {
      const idx = this.days.findIndex(d => d.id === day.id);
      if (idx >= 0) {
        this.days.splice(idx, 1);
      } else {
        this.days.push({
          id: day.id,
          date: day.date,
        });
      }
    }
    
  }
};
</script>