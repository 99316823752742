<template>
  <div class="home">
    <navbar v-bind:menulist="$store.state.mainmenu"></navbar>
    <herobig v-bind:title="$t('title')"></herobig>

    <h2 class="welcome">{{$t('subtitleELearning')}}</h2>

    <boxcontainer>

      <div class="row" v-if="!showvideo">
        <div class="col-lg-10 col-md-10 col-xs-10 col-sm-10 mx-auto">
          <almatable v-bind:cols="clearning" v-bind:rows="rlearning" v-on:click="open($event)"></almatable>
        </div>
      </div>

      <div class="row" v-if="showvideo">
        <div class="col mx-auto">

          <h2 class="centratox ">{{titoloMateriale}}</h2>
          <p class="centratox">È consigliabile utilizzare il dispositivo in orizzontale
            <img src="img/landscape.png" style="width: 32px">
          </p>
          <div class="embed-responsive embed-responsive-16by9">
            <iframe  v-bind:src="linkMateriale" width="640" height="360" style="border:none" class="embed-responsive-item" 
                    allow="autoplay; fullscreen" allowfullscreen="yes" scrolling="no" marginheight="0" marginwidth="0">
            </iframe>
          </div>
          <div style="padding-top:20px;">
            <button type="submit" class="btn btn-primary" v-on:click="showvideo=false">{{$t('Indietro')}}</button>
          </div>

        </div>
      </div>

    </boxcontainer>
    <footerline></footerline>

  </div>
</template>

<script>
  // @ is an alias to /src
  import navbar from '@/components/navbar.vue'
  import herobig from '@/components/herobig.vue'
  import boxcontainer from '@/components/boxcontainer.vue'
  import footerline from '@/components/footerline.vue'
  import almatable from '@/components/almatable.vue'

  export default {
    name: 'Home',
    components: {
      navbar,
      herobig,
      boxcontainer,
      footerline,
      almatable,
    },
    data: function () {
      return {
        titoloMateriale: '',
        linkMateriale: '',
        showvideo: false,
        clearning: [
          
        ],
        rlearning: [ ],
      };
    },
    mounted: function () {
      var self=this;
      this.setTitle();
      this.$store.dispatch('getCorsiAttivi').then(function() {
        self.$store.dispatch('getMateriali', self.$i18n.locale).then(function() {
          console.log('loaded');
          self.$store.state.materiali.forEach(function(m) {
            self.rlearning.push( { Id: m.Id, titolo: m.Name, tipo: m.Tipo_Risorsa__c, link: m.Link_Risorsa__c})
          })
        });
      });
    },
    methods: {
      setTitle:function(){
        this.clearning.push(
          { title: this.$i18n.t('Lezione'), name: 'titolo' },
          { title: this.$i18n.t('Tipo'), name: 'tipo'},
        )
      },
      open: function(m) {
        this.showvideo=true;
        this.linkMateriale=m.link;
        this.titoloMateriale=m.titolo;
      }
    }
  }
</script>