<template>
  <div class="home">
    <navbar :menulist="$store.state.mainmenu" ></navbar>
    <herobig :title="$t('title')"></herobig>

    <h2 class="welcome">{{$t('subtitleStudente')}}</h2>

    <boxcontainer>
        <div class="row">
          <div :class="['mx-auto col-lg-' + box.w]" v-for="box in boxes" :key="box.id">
            <box 
              :imgname="box.imgname" 
              :alttext="$t('boxesStudente' + box.id + '.alttext')"
              :title="$t('boxesStudente' + box.id + '.title')" 
              :menulist="box.menulist"
              :item="box.item"
              :extlink="box.extlink"
              :disabled="box.disabled"
            >
            </box>
          </div>
        </div>
    </boxcontainer>

    <footerline></footerline>
  </div>
</template>

<script>
  // @ is an alias to /src
  import navbar from '@/components/navbar.vue'
  import herobig from '@/components/herobig.vue'
  import box from '@/components/box.vue'
  import boxcontainer from '@/components/boxcontainer.vue'
  import footerline from '@/components/footerline.vue'

  export default {
    name: 'Home',
    components: {
      navbar,
      herobig,
      box,
      boxcontainer,
      footerline
    },
    data: function () {
      return {
        boxes: []
      }
    },
    mounted: function() {
      var w = 12 / 4 //4

      this.boxes.splice(0,this.boxes.length)
      this.boxes.push({
        id: 1,
        w: w,
        disabled: false,
        imgname: 'img/regolamento.jpg',
        item: {title:'Regolamento', path:'Regolamento'}
      })
      this.boxes.push({
        id: 2,
        w: w,
        disabled: false,
        imgname: 'img/badge.jpg',
        alttext: 'Utility',
        title: 'Utility',
        item: {title:'Utility', path:'Utility'}
      })
      this.boxes.push({
        id: 3,
        w: w,
        disabled: false,
        imgname: 'img/didattica.jpg',
        item: {title:'Didattica', path:'Didattica'}
      })
      /*
      this.boxes.push({
        id: 4,
        w: w,
        imgname: 'img/extradidattica.jpg',
        item: {title:'Extra Didattica', path:'ExtraDidattica'}
      })
      */
      this.boxes.push({
        id: 5,
        w: w,
        disabled: !this.$store.getters.prenotazioni,
        imgname: 'img/badge.jpg',
        alttext: 'Prenotazioni',
        title: 'Prenotazioni',
        item: {title:'Prenotazioni', path:'Prenotazioni'},
      })
      if (this.$i18n.locale == 'en') this.boxes.push({
        id: 6,
        w: w,
        imgname: 'img/extradidattica.jpg',
        alttext: 'Prenotazioni',
        title: 'Prenotazioni',
        extlink: 'https://www.alma.scuolacucina.it/en/living-and-studying-in-parma/'
      })
    }
  }
</script>