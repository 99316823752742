<template>
<div class="table-responsive-sm">
  <table id="tablePreview" class="table table-sm table-striped table-borderless">
    <thead>
      <tr>
        <th v-for="c in cols" v-bind:key="c.name">{{c.title}}</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="r in rows" >
        <tr v-bind:key="r.Id" v-on:click.prevent="$emit('click',r)">
          <!-- <th v-for="ch in firstcol" v-bind:key="ch" scope="row" >{{ch}}</th> -->
          <td v-for="(cd,index) in cols" v-bind:key="cd.name" class="mob" style="white-space:normal">
            <span v-if="cols.length==(index+1) && r['upload']" >
              <button class="btn btn-outline-secondary btn-sm" v-on:click.prevent="$emit('upload',r.Id)">{{$t('Carica')}}</button>
            </span>
            <span v-else>
              {{ $t(r[cd.name]) | colfilter(cd) }}
            </span>
          </td>
        </tr>
        <tr v-if="r.note" v-bind:key="r.Id+'note'" v-on:click="$emit('click',r)">
          <td>&nbsp;</td>
          <td v-bind:colspan="cols.length-1" >{{ r['note'] }}</td>
        </tr>
      </template>
    </tbody>
  </table>
  </div>
</template>

<script>
  import moment from 'moment'
  import numeral from 'numeral'

  export default {
    name: 'almatable',
    props: {
      cols: Array,
      rows: Array,
      filters: Array
    },
    computed: {
      firstcol: function() {
        return this.cols[0];
      },
      nextcols: function() {
        return this.cols.slice(1);
      },
    },
    data: function () {
      return {}
    },
    filters: {
      colfilter: function(v, col) {
        if (col.filter) {
          switch(col.filter) {
            case 'currency':
              var v2=numeral( parseFloat(v) ).format('$ 0,0.00');
              return v2;
            case 'date':
              if (v) {
                var md=new moment(v);
                return md.format('DD-MM-YYYY');
              }
              return v;
            case 'datetime':
              var mdt=new moment(v);
              return mdt.format('DD-MM-YYYY');
            default:
              return v;
          }
        }
        return v;
      }
    },
    methods: {
      goto: function (item) {
        console.log('goto:')
        console.log(item)
        this.activeTab = item;
      }
    },
    created: function() {}
  }
</script>

<style scoped>
</style>