<template>

    <!-- 3 box sottosezioni -->
    <section class="section-wrap pb-lg-20 pb-md-20 pb-sm-20">
      <div class="container">
        <div class="row justify-content-center">
          
        </div>

        <slot></slot>

      </div>
    </section>

</template>

<script>
  export default {
    name: 'boxcontainer',
    props: {
    },
    methods: {
    }
  }
</script>

<style scoped>
</style>