<template>
    <div class="home">
        <navbar :menulist="$store.state.mainmenu" />

        <div class="row mx-auto pt-2">
            <div class="col-lg-12 col-md-12 col-xs-12 ml-2">
                <button type="button" class="btn btn-outline-dark btn-sm" @click="back">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                    </svg>
                    {{$t('Indietro')}}
                </button>
            </div>
        </div>

        <!-- success -->
        <div v-if="success === 's'">
            <p class="msg"> Grazie per aver acquistato! Ti verrà inviata un'email di conferma per l'ordine. </p>
        </div>

        <!-- failure -->
        <div v-if="success === 'f'">
            <p class="msg"> Attenzione, il pagamento non è andato a buon fine. Riprova più tardi. </p>
        </div>

        <footerline />
    </div>
</template>

<script>
    import navbar     from '@/components/navbar.vue'
    import footerline from '@/components/footerline.vue'

    export default {
        name: 'EcommerceTYP',
        components: { navbar, footerline },
        props: { success: String },
        mounted: function() {},
        methods: {
            back: function() { this.$router.push({ name: 'EcommerceHome' }) }
        }
    }
</script>

<style>
    .msg {
        padding-top: 50px;
        padding-bottom: 50px;
        text-align: center;
        font-size: larger;
    }
</style>