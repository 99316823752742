<template>
    <div class="home">
        <navbar :menulist="$store.state.mainmenu" />
        
        <div class="row mx-auto pt-2">
            <div class="col-lg-12 col-md-12 col-xs-12 ml-2">
                <button type="button" class="btn btn-outline-dark btn-sm" @click="back">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                    </svg>
                    {{$t('Indietro')}}
                </button>
            </div>
        </div>

        <div class="container" :class="{disabled: !this.$store.getters.isRicaricaVisible}">

            <h2 class="welcome">{{$t('RicaricaBadge')}}</h2>

            <div class="row mx-auto d-flex justify-content-center">
                <div class="col-lg-4 col-md-4 col-sm-10 col-xs-12">
                    <img src="img/corsieopendays.jpg" width="50%" class="rounded mx-auto d-block">
                </div>

                <div class="col-lg-6 col-md-6 col-sm-10 col-xs-12 mx-3">
                    <div class="row mt-2">
                        <div class="col-6 font-weight-bold">{{$t('Prezzo')}}</div>
                        
                        <div class="col-6">
                            <div class="form-group">
                                <div class="input-group mb-1">
                                    <select class="custom-select custom-select-sm mb-1 w-50"
                                            id="inputGroupSelect" size="1" v-model="price"
                                            :class="!price ? 'is-invalid' : 'is-valid'"
                                            :title="$t('Scegliunaopzione')" required
                                    >
                                        <option v-for="p in prices" :key="p.Id" :value="p">{{p.Prezzo__c | amount}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-12 font-weight-bold text-right display-4">{{price.Prezzo__c | amount}}</div>
                    </div>

                    <div class="row pt-3 btngroup">
                        <div class="col-12 font-weight-bold text-right">
                            <button @click.prevent="buy" type="button" class="btn btn-primary">
                                <small>
                                    {{$t('Acquistaora')}}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                                    </svg>
                                </small>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <footerline />
        
    </div>
</template>

<script>
    import navbar       from '@/components/navbar.vue'
    import footerline   from '@/components/footerline.vue'
    import numeral      from 'numeral'

    export default {
        name: 'EcommerceBadge',
        components: {
            navbar, footerline
        },
        data: function() {
            return {
                prices: [],
                price: {}
            }
        },
        filters: {
            amount: v => numeral(v).format('0,0[.]00 $')
        },
        mounted: function() {
            this.$store.dispatch('getProdEcommerce', { ricerca: '', filtro: 'RicaricheBadge', crit: '' })
                .then(r => {
                    this.prices = r.sort((a, b) => a.Prezzo__c - b.Prezzo__c)
                    this.price = this.prices[0]
                })
        },
        methods: {
            back: function() {
                this.$router.push({ name: 'EcommerceHome' })
            },
            buy: function() {
                this.price.tot = this.price.Prezzo__c
                this.price.qty = 1
                this.$router.push({ name: 'EcommerceRiepilogo', params: { badgeprod: this.price } })
            }
        }
    }
</script>

<style>
    svg { vertical-align: sub }

    .btngroup { border-top: 1px solid #d29500 }

    .disabled{
        pointer-events: none;
        opacity: 0.4;
    }
</style>