<template>
  <div>
    <hr class="my-1" />

    <h2 class="welcome ingtitle">{{ titolo }}</h2>

    <div class="d-none d-sm-none d-md-block">
      <div v-if="ingredienti.length == 0" class="text-center mb-2">
        {{ tipo == 'Ingrediente' ? $t('NoIng') : $t('NoAttr') }}
      </div>

      <div v-if="!noneed">
        <div class="row text-center" v-if="ingredienti.length > 0">
          <div :class="{ 'col-3' : costoEnable, 'col-4' : !costoEnable}">
            <label>{{$t('Categoria')}}</label>
          </div>
          <div :class="{ 'col-3' : costoEnable, 'col-4' : !costoEnable}">
            <label>{{ $t('NomeIng') }}</label>
          </div>
          <div v-if="costoEnable" class="col-1" style="text-align: center;padding:0">
          <!-- <div v-if="this.almenoUnCostoAssente" class="col-1" style="text-align: center"> -->
            <label>Costo UM/€</label>
          <!-- </div> -->
          </div>
          <div class="col-1" style="text-align: center">
            <label>U.M.</label>
          </div>
          <div class="col-2">
            <label>{{$t('Quantità')}}</label>
          </div>
          <div v-if="costoEnable" class="col-1" style="text-align: center;padding:0 0 0 7px">
          <!-- <div v-if="this.almenoUnCostoAssente" class="col-1" style="text-align: center"> -->
            <label>{{$t('Totale')}} €</label>
          <!-- </div> -->
          </div>
          <div class="col-1 text-right">
            <label></label>
          </div>
        </div>
        
        <!-- Casella Categoria -->
        <div class="row" v-for="(ingrediente, index) in ingredienti" :key="ingrediente.Ordinatore__c">
          <div :class="{ 'col-3' : costoEnable, 'col-4' : !costoEnable}">
            <div class="form-group" v-if="condId && ingrediente.Id">
              <input :class="!ingrediente.Categoria__c ? 'is-invalid' : ''" maxlength="255" :disabled="true" required type="text" 
                class="form-control" :placeholder="'Non inserire ' + titolo" v-model="ingrediente.Categoria__c" />
            </div>
            <div class="form-group" v-else-if="condId && categorie.length > 0">
              <select :class="!ingrediente.Categoria__c ? 'is-invalid' : ''" :disabled="blocco" v-model="ingrediente.Categoria__c" @change="getIngr(index)" class="form-control" :title="$t('Scegliunaopzione')" required>
                <option v-for="c in categorie" :key="c" :value="c">{{$t(c)}}</option>
              </select>
            </div>
          </div>

          <!-- Casella ingrediente -->
          <div :class="{ 'col-3' : costoEnable, 'col-4' : !costoEnable}">
            <div class="form-group" v-if="!condId || categorie.length == 0">
              <input :class="!ingrediente.Note__c ? 'is-invalid' : ''" maxlength="255" :disabled="blocco" required type="text" 
                class="form-control" :placeholder="$t('Inserisci') + titolo" v-model="ingrediente.Note__c" />
            </div>

            <div class="form-group" v-else-if="condId && ingrediente.Id">
              <input :class="!ingrediente.Note__c ? 'is-invalid' : ''" maxlength="255" :disabled="true" type="text" 
                class="form-control" :placeholder="'Non inserire ' + titolo" v-model="ingrediente.Note__c" />
            </div>

            <div class="form-group" v-else-if="condId && categorie.length > 0 && ingrediente.valori && ingrediente.valori.length > 0">
              <select :class="!ingrediente.Note__c ? 'is-invalid' : ''" :disabled="blocco" v-model="ingrediente.Note__c" 
                class="form-control" @change="setUnita($event, index)" :title="$t('Scegliunaopzione')" required>
                <option v-for="v in ingrediente.valori" :value="v.Name" :key="v.Id">{{ v.Name }}</option>
              </select>
            </div>

            <div class="form-group" v-else>
              <select class="form-control" disabled></select>
            </div>
          </div>

          <!-- Costo per Unità di Misura -->
          <div v-if="costoEnable" class="col-1">
            <div class="form-group">
                <input type="number" :disabled="true" class="form-control"
                :value="ingrediente.Ignora_Costo__c ? costoIgnorato : ingrediente.Prodotto__r.Costo__c"/>
            </div>
          </div>

          <!-- U.M. -->
          <div class="col-1">
            <div class="form-group" v-if="!condId || categorie.length == 0">
              <select :class="!ingrediente.Unita_di_Misura__c ? 'is-invalid' : ''" :disabled="blocco" v-model="ingrediente.Unita_di_Misura__c" class="form-control" :title="$t('Scegliunaopzione')" required>
                <option v-for="v in getPicklistValues('Product2', 'Unita_di_misura__c')" :key="v" :value="v">{{ $t(v) }}</option>
              </select>
            </div>
            <div class="form-group" v-if="condId && categorie.length > 0">
              <input :disabled="true" required type="text" class="form-control" placeholder="" v-model="ingrediente.Unita_di_Misura__c" />
            </div>
          </div>

          <!-- Qta -->
          <div class="col-2">
            <div class="form-group">
              <!-- con v-bind:value rimane rosso (il required non è mai soddisfatto?) bloccando il salvataggio -->
              <!-- <input :class="!ingrediente.Quantita__c ? 'is-invalid' : ''" @change="$emit('update',[ingrediente, index])" :step="step" :disabled="blocco" min="0" required type="number" class="form-control" placeholder="" v-bind:value="ingrediente.Quantita__c" /> -->
              <input :class="!ingrediente.Quantita__c ? 'is-invalid' : ''" @change="$emit('update',[ingrediente, index])" :step="step" :disabled="blocco" min="0" required type="number" class="form-control" placeholder="" v-model="ingrediente.Quantita__c" />
            </div>
          </div>

          <!-- Totale -->
          <div v-if="costoEnable" class="col-1" style="padding-right:0">
            <div class="form-group">
                <input type="text" :disabled="true" class="form-control totRiga" :value="ingrediente.Ignora_Costo__c ? costoIgnorato : ingrediente.Costo_Quantita__c"/>
            </div>
          </div>

          <!-- Trash -->
          <div class="col-1 text-right" style="padding-left:0">
            <div class="form-group">
              <button type="submit" :disabled="blocco" class="btn btn-danger" @click.prevent="$emit('del', index)"><span class="oi oi-trash"></span></button>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="!blocco">
        <div class="col-3">
          <div class="form-group">
            <div class="form-check">
              <button class="btn" :class="noneed ? 'btn-primary' : 'btn-outline-primary'" type="checkbox" @click="$emit('changeneed')">
                {{ $t('NeedNo') + titolo }}
              </button>
            </div>
          </div>
        </div>

        <div class="col-7"></div>

        <div class="col-2 text-right">
          <div class="form-group">
            <button type="submit" :disabled="blocco || noneed" class="btn btn-primary" @click.prevent="$emit('add')"><span class="oi oi-plus"></span></button>
          </div>
        </div>
      </div>
    </div>

    <div class="d-block d-sm-block d-md-none ctext">
      <div v-if="!noneed">
        <div class="card mb-1" v-for="(ingrediente, index) in ingredienti" :key="ingrediente.Ordinatore__c">
          <div class="card-body pb-0">
            <div class="row" v-if="condId && categorie.length > 0">

              <!-- Categoria -->
              <div class="col-4">
                <label>{{ $t('Categoria') }}</label>
              </div>
              <div class="col-8">
                <div class="form-group" v-if="!ingrediente.Id">
                  <select :class="!ingrediente.Categoria__c ? 'is-invalid' : ''" :disabled="blocco" v-model="ingrediente.Categoria__c" @change="getIngr(index)" class="form-control" :title="$t('Scegliunaopzione')" required>
                    <option v-for="c in categorie" :key="c" :value="c">{{$t(c)}}</option>
                  </select>
                </div>
                <div class="form-group" v-else>
                  <input :class="!ingrediente.Categoria__c ? 'is-invalid' : ''" maxlength="255" :disabled="true" required type="text" 
                    class="form-control" :placeholder="'Non inserire ' + titolo" v-model="ingrediente.Categoria__c" />
                </div>
              </div>

              <!-- Ingrediente -->
              <div class="col-4">
                <label>{{ $t('NomeIng') }}</label>
              </div>

              <div class="col-8">
                <div class="form-group" v-if="!condId || categorie.length == 0">
                  <input :class="!ingrediente.Note__c ? 'is-invalid' : ''" maxlength="255" :disabled="blocco" required type="text" 
                    class="form-control" :placeholder="$t('Inserisci') + titolo" v-model="ingrediente.Note__c" />
                </div>

                <div class="form-group" v-else-if="condId && ingrediente.Id">
                  <input :class="!ingrediente.Note__c ? 'is-invalid' : ''" maxlength="255" :disabled="true" required type="text" 
                    class="form-control" :placeholder="'Non inserire ' + titolo" v-model="ingrediente.Note__c" />
                </div>

                <div class="form-group" v-else-if="condId && categorie.length > 0 && ingrediente.valori && ingrediente.valori.length > 0">
                  <select :class="!ingrediente.Note__c ? 'is-invalid' : ''" :disabled="blocco" v-model="ingrediente.Note__c" 
                    class="form-control" @change="setUnita($event, index)" :title="$t('Scegliunaopzione')" required>
                    <option v-for="v in ingrediente.valori" :value="v.Name" :key="v.Id">{{ v.Name }}</option>
                  </select>
                </div>

                <div class="form-group" v-else>
                  <select class="form-control" disabled></select>
                </div>
              </div>

              <!-- INIZIO VISUALIZZAZIONE SMARTPHONE CON COSTI -->
              <div class="inline" v-if="costoEnable">

              <!-- Qta -->
              <div class="inline">
                <div class="col-4">
                  <label>{{ $t('Qta') }}</label>
                </div>
                <div class="col-8">
                  <div class="form-group">
                    <!-- con v-bind:value rimane rosso (il required non è mai soddisfatto?) bloccando il salvataggio -->
                    <!-- <input :class="!ingrediente.Quantita__c ? 'is-invalid' : ''" @change="$emit('update',[ingrediente, index])" :step="step" :disabled="blocco" min="0" required type="number" class="form-control" placeholder="" v-bind:value="ingrediente.Quantita__c" /> -->
                    <input :class="!ingrediente.Quantita__c ? 'is-invalid' : ''" @change="$emit('update',[ingrediente, index])" :step="step" :disabled="blocco" min="0" required type="number" class="form-control" placeholder="" v-model="ingrediente.Quantita__c" />
                  </div>
                </div>
              </div>

              <!-- Totale -->
              <div class="inline" v-if="costoEnable">
                <div class="col-6" v-if="ingrediente.Tipo__c == 'Ingrediente'">
                  <label>{{$t('Totale')}} €</label>
                </div>
                <div class="col-6" v-if="ingrediente.Tipo__c == 'Ingrediente'">
                  <div class="form-group">
                      <input type="text" :disabled="true" class="form-control totRiga" :value="ingrediente.Ignora_Costo__c ? costoIgnorato : ingrediente.Costo_Quantita__c"/>
                  </div>
                </div>
              </div>

              </div>
              
              <div class="inline reduceCosti" v-if="costoEnable">

                <!-- Costo -->
                <div class="col-3" v-if="costoEnable && ingrediente.Tipo__c == 'Ingrediente'">
                  <label class="reduceLabel">Costo U.M./€</label>
                </div>
                <div class="col-4" v-if="costoEnable && ingrediente.Tipo__c == 'Ingrediente'">
                  <div class="form-group">
                      <input type="text" :disabled="true" class="form-control reduceInput" :value="ingrediente.Ignora_Costo__c ? costoIgnorato : ingrediente.Prodotto__r.Costo__c"/>
                  </div>
                </div>

                <!-- U.M. -->
                <div class="col-2">
                  <label class="reduceLabel">U.M.</label>
                </div>
                <div class="col-3">
                  <div class="form-group" v-if="!condId || categorie.length == 0">
                    <select :class="!ingrediente.Unita_di_Misura__c ? 'is-invalid' : ''" :disabled="blocco" v-model="ingrediente.Unita_di_Misura__c" class="form-control" :title="$t('Scegliunaopzione')" required>
                      <option v-for="v in getPicklistValues('Product2', 'Unita_di_misura__c')" :key="v" :value="v">{{ $t(v) }}</option>
                    </select>
                  </div>
                  <div class="form-group" v-if="condId && categorie.length > 0">
                    <input :disabled="true" required type="text" class="form-control reduceInput" placeholder="" v-model="ingrediente.Unita_di_Misura__c" />
                  </div>
                </div>

              </div>
              <!-- FINE VISUALIZZAZIONE SMARTPHONE CON COSTI -->

              <!-- INIZIO VISUALIZZAZIONE SMARTPHONE SENZA COSTI -->
              <div class="inline" v-if="!costoEnable">

                <!-- U.M. -->
                <div class="col-2">
                  <label>U.M.</label>
                </div>
                <div class="col-3">
                  <div class="form-group" v-if="!condId || categorie.length == 0">
                    <select :class="!ingrediente.Unita_di_Misura__c ? 'is-invalid' : ''" :disabled="blocco" v-model="ingrediente.Unita_di_Misura__c" class="form-control" :title="$t('Scegliunaopzione')" required>
                      <option v-for="v in getPicklistValues('Product2', 'Unita_di_misura__c')" :key="v" :value="v">{{ $t(v) }}</option>
                    </select>
                  </div>
                  <div class="form-group" v-if="condId && categorie.length > 0">
                    <input :disabled="true" required type="text" class="form-control" placeholder="" v-model="ingrediente.Unita_di_Misura__c" />
                  </div>
                </div>
                <!-- Qta -->
                <div class="inline">
                  <div class="col-4">
                    <label>{{ $t('Qta') }}</label>
                  </div>
                  <div class="col-8">
                    <div class="form-group">
                      <!-- con v-bind:value rimane rosso (il required non è mai soddisfatto?) bloccando il salvataggio -->
                      <!-- <input :class="!ingrediente.Quantita__c ? 'is-invalid' : ''" @change="$emit('update',[ingrediente, index])" :step="step" :disabled="blocco" min="0" required type="number" class="form-control" placeholder="" v-bind:value="ingrediente.Quantita__c" /> -->
                      <input :class="!ingrediente.Quantita__c ? 'is-invalid' : ''" @change="$emit('update',[ingrediente, index])" :step="step" :disabled="blocco" min="0" required type="number" class="form-control" placeholder="" v-model="ingrediente.Quantita__c" />
                    </div>
                  </div>
                </div>

              </div>
              <!-- FINE VISUALIZZAZIONE SMARTPHONE SENZA COSTI -->

              <!-- trash -->
              <!-- <div class="col-6" v-if="costoEnable && ingrediente.Tipo__c == 'Ingrediente' && ingrediente.Costo_Quantita__c">
                <div class="form-group text-center" v-if="!blocco">
                  <button type="submit" class="btn btn-danger" @click.prevent="$emit('del', index)"><span class="oi oi-trash"></span></button>
                </div>
              </div>
              <div class="col-12" v-else> -->
              <div class="col-12">
                <div class="form-group text-center" v-if="!blocco">
                  <button type="submit" class="btn btn-danger" @click.prevent="$emit('del', index)"><span class="oi oi-trash"></span></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="ingredienti.length == 0" class="text-center mb-2">
        {{ tipo == 'Ingrediente' ? $t('NoIng') : $t('NoAttr') }}
      </div>

      <div class="row pt-2" v-if="!blocco">
        <div :class="'col-' + (noneed ? '12' : '9')">
          <div class="form-group text-center">
            <div class="form-check">
              <button class="btn" :class="noneed ? 'btn-primary' : 'btn-outline-primary'" type="checkbox" @click="$emit('changeneed')">
                {{ $t('NeedNo') + titolo }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-3" v-if="!noneed">
          <div class="form-group text-center">
            <button type="submit" :disabled="blocco" class="btn btn-primary" @click.prevent="$emit('add')"><span class="oi oi-plus"></span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // import { ModelListSelect } from 'vue-search-select'
  // import { BFormInput } from 'bootstrap-vue'
  
  export default {
    name: 'tabellapiatto',
    components: {
      // BFormInput
      // ModelListSelect
    },
    props: {
      ingredienti: Array,
      titolo:      String,
      tipo:        String,
      condId:      String,
      blocco:      Boolean,
      step:        Number,
      noneed:      Boolean,
      costoEnable: Boolean,
    },
    data: function() {
      return {
        categorie:   [],
        catSelected: '',
        costoIgnorato: 0,
        // almenoUnCostoAssente: false
      }
    },
    mounted: function() {
      this.$store.dispatch('getCampi', ['Product2']).then(() => {
        console.log('get campi finito')
        // console.log(resp)
      })

      // var self = this

      this.$store.dispatch('getCategoriaIngrAttr', { tipo: this.tipo, condizioneId: this.condId }).then(resp =>
        this.categorie = resp
      )

      console.log('mounted this.ingredienti', this.ingredienti)
      console.log('this.ingredienti.length', this.ingredienti.length)
      this.ingredienti.forEach((d,i) => {
        console.log('d',d,'i',i)
        if (d.Categoria__c) this.getIngr(i)
      })
    },
    methods: {
      getPicklistValues: function(o,f) {
        // console.log('getPicklistValues')
        // console.log(this.$store.state.campi)

        if (this.$store.state.campi) {
          if (this.$store.state.campi.has(o)) {
            console.log('oggetto')

            var af = this.$store.state.campi.get(o)

            if (af.has(f)) {
              console.log('campo')

              var afv = af.get(f)

              console.log(afv)
              
              if (afv) return afv.values.split(';')
            }
          }
        }

        return []
      },
      getIngr: function(index) {
        // var self = this
        console.log('--> (getIngr) recupero prodotti tesi')
        console.log('index', index)
        this.$store.dispatch('getIngredientiAttrezzaturaTesi', {tipo: this.tipo, condizioneId: this.condId, categoria: this.ingredienti[index].Categoria__c})
        .then(resp => {
          // this.ingredienti[index].valori = resp;
          console.log('prodotti_tesi: ',resp)
          /*for(var r of resp) {
            // console.log('r',r)
            if(r.Costo__c == null) this.almenoUnCostoAssente = true
          }*/
          
          this.$set(this.ingredienti[index],'valori', resp)
          this.ingredienti[index]['valori'] = resp
          console.log('getIngr this.ingredienti',this.ingredienti)
        })
      },
      setUnita: function(e, i) {
        console.log('F --> setUnita')
        console.log('e.target.value',e.target.value)
        console.log('i',i)

        var unit = '', codArca = '', costo = '', costoQuantita = 0, idProdotto = ''
        // var quantita = ''
        var ignoraCosto = false

        this.ingredienti[i].valori.forEach(p => {
          if(p.Name == e.target.value) {
            console.log('valori',p)

            unit          = p.Unita_di_misura__c
            codArca       = p.ProductCode
            costo         = p.Costo__c
            ignoraCosto   = p.Ignora_Costo__c
            idProdotto    = p.Id
            // if(p.Prodotto__c && p.Prodotto__r.Costo__c && p.Quantita__c) costoQuantita = (p.Prodotto__r.Costo__c * p.Quantita__c).toFixed(2)
            if(p.Prodotto__c && p.Costo__c && p.Quantita__c) costoQuantita = p.Costo__c * p.Quantita__c
            if(p.Costo__c == 0 || p.Costo__c == null) ignoraCosto = true
          }
        })

        this.ingredienti[i].Unita_di_Misura__c          = unit
        this.ingredienti[i].Codice_Arca__c              = codArca
        this.ingredienti[i].Costo_Quantita__c           = costoQuantita
        this.ingredienti[i]['Prodotto__r']['Costo__c']  = costo
        this.ingredienti[i]['Prodotto__c']              = idProdotto
        this.ingredienti[i].Ignora_Costo__c             = ignoraCosto

      },
      // setThreeDecimal: function(ing, index) {
      //   console.log('F --> setThreeDecimal')
      //   console.log('index',index)
      //   if (this.tipo == 'Ingrediente') ing.Quantita__c = parseFloat(ing.Quantita__c).toFixed(3)
      //   // console.log('quantità',ing.Quantita__c)
      //   // console.log('ing', ing)
      //   // console.log('piatto',this.$store.state.piatto)
      //   console.log(ing.Prodotto__r.Costo__c+' * '+ ing.Quantita__c +' = '+ ing.Prodotto__r.Costo__c * ing.Quantita__c, (ing.Prodotto__r.Costo__c * ing.Quantita__c).toFixed(2))
      //   console.log(parseFloat(ing.Quantita__c), typeof(parseFloat(ing.Quantita__c)))
      //   var costoPerQuantita = ing.Prodotto__r.Costo__c * parseFloat(ing.Quantita__c)
      //   costoPerQuantita = costoPerQuantita.toFixed(2)
      //   console.log('costoPerQuantita',costoPerQuantita, typeof(costoPerQuantita))
      //   if(costoPerQuantita != 0) {
      //     ing.Costo_Quantita__c = costoPerQuantita;
      //     // this.$forceUpdate(); // brutto
      //     // this.$emit('', this.ingredienti[index].Costo_Quantita__c = costoPerQuantita)
      //     console.log('ing.Costo_Quantita__c',ing.Costo_Quantita__c,typeof(ing.Costo_Quantita__c))
          
      //   }
      // },
    }
  }
</script>

<style scoped>
    .inline {
      display:flex;
    }
    .totRiga {
      background-color: lightgreen;
      border: lightgreen;
    }
    .reduceLabel {
      font-size:70%;
    }
    .reduceInput {
      width:80%;
      height:18px;
    }

  .reduceCosti > .col-4,
  .reduceCosti > .col-3,
  .reduceCosti > .col-1 {
    padding:0
  }
</style>