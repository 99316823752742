<template>
  <div class="home">
    <navbar v-bind:menulist="$store.state.mainmenu" ></navbar>
    <herobig v-bind:title="$t('title')"></herobig>

    <h2 align="center" class="welcome">{{$t('subtitleRegolamento')}}</h2>

    <div class="row">
      <!-- il regolamento -->
      <div class="col-10 mx-auto" v-if="this.$i18n.locale=='it'">
        <h2>NORME COMPORTAMENTALI: DIVISA</h2>
        <p class="par">La frequenza ai corsi ALMA richiede un abbigliamento consono alle lezioni e alla tipologia di corso. </p>
        <p></p>
        <h3><b>Corsi Cucina, Pasticceria, Ospitalità, Panificazione</b></h3>
        <ol type="a">
          <li>Per le allieve: devono assumere un aspetto decoroso e professionale. I capelli devono essere curati e, se lunghi, raccolti all’interno della toque.</li>
          <li>Per gli allievi: devono assumere un aspetto decoroso e professionale. I capelli devono essere corti e curati, se tenuti con un taglio lungo, raccolti all’interno della toque; la barba rasata.</li>
          <li>Ogni allievo dovrà farsi carico del lavaggio della divisa.</li>
          <li>La divisa ALMA è l’unica consentita per la partecipazione alle lezioni e deve essere pulita, stirata ed in ordine. La divisa deve essere indossata nel corso di tutte le lezioni teoriche e pratiche e non può essere indossata al di fuori della scuola. Gli allievi devono indossare la divisa effettuando il cambio d’abito negli appositi spogliatoi della Scuola.</li>
          <li>Nel corso delle lezioni training dovrà essere sempre indossata la toque o apposito copricapo di colore bianco. </li>
        </ol>
        <p></p>
        <h3><b>Corso Manager della Ristorazione</b></h3>
        <ol type="a">
          <li>L’aspetto deve essere ordinato e consono alla partecipazione ad un Master.</li>
          <li>Ogni allievo dovrà obbligatoriamente esporre il cartellino nominativo, in mancanza del quale non potrà accedere ai locali della scuola.</li>
          <li>Durante le lezioni e le visite in azienda è richiesto un abbigliamento di tipo formale (giacca e cravatta per gli uomini e tailleur gonna/pantalone per le donne); no jeans, scarpe da ginnastica ecc.</li>
        </ol>
        <p></p>
        <h3><b>Corso Master ALMA AIS</b></h3>
        <ol type="a">
          <li>Durante le lezioni e le uscite didattiche i Corsisti devono indossare la divisa di rappresentanza AIS, che dev’essere ordinata e completa di: giacca blu con scudetto, camicia bianca, cravatta/foulard, pantaloni/gonna grigi, scarpe nere o blu per le donne, e non liberamente interpretata dai singoli (no jeans no scarpe da ginnastica ecc).</li>
        </ol>
        <p></p>
        <h2>NORME COMPORTAMENTALI GENERALI</h2>
        <ol type="a">
          <li>La postazione di lavoro assegnata nell’aula di esercitazione pratica deve essere lasciata pulita e in ordine.Gli allievi sono tenuti alla massima cura delle attrezzature di lavoro presenti nei laboratori.</li>
          <li>Gli orari delle lezioni vanno rispettati: gli allievi devono trovarsi in aula dieci minuti prima dell’inizio effettivo della lezione, per organizzare la postazione di lavoro. Non sono quindi ammessi ritardi salvo necessità giustificate di entrate posticipate/uscite anticipate che saranno concesse previo rilascio dell’apposito permesso rilasciato dalla Segreteria Studenti.</li>
          <li>Durante le lezioni è vietato assentarsi dall’aula se non per gravi motivi e comunque previa autorizzazione del docente. Per le normali necessità si dovranno utilizzare gli intervalli previsti sia nella mattina che nel pomeriggio</li>
          <li>è tollerato un monte ore assenze massimo pari al 10% del monte ore totale. Qualora l’assenza fosse attribuita a malattia è necessario presentare a mano apposita certificazione medica, presso la Segreteria Studenti, tassativamente al momento del reinserimento dell’allievo alle lezioni. La documentazione medica è necessaria a giustificare le ore di assenza e contemporaneamente comunica l’idoneità dell’allievo alla frequenza. La frequenza è obbligatoria</li>
          <li>Tutti gli allievi sono tenuti a compilare obbligatoriamente ogni settimana sulla piattaforma online di ALMA i questionari di valutazione della qualità delle lezioni. </li>
          <li>La Scuola declina ogni responsabilità in merito ad eventuali furti. Qualora dovessero verificarsi furti o atti vandalici la Scuola è autorizzata a convocare allievi per le dovute verifiche in merito all’accaduto. </li>
          <li>Oggetti smarriti: tutti coloro che trovassero oggetti abbandonati o presumibilmente persi, sono pregati di portarli presso la Segreteria Studenti. Tutti gli allievi che avessero smarrito effetti personali, possono fare riferimento alla Segreteria Studenti.</li>
          <li>Il pasto viene garantito dalla scuola nella sala Ristorante. Gli allievi a fine pasto devono riporre le stoviglie utilizzate negli appositi contenitori ed effettuare la raccolta differenziata dei rifiuti.</li>
          <li>Pur nel rispetto di qualunque credo religioso, di regimi alimentari particolari o in caso di allergie e intolleranze non si ritiene in obbligo di garantire pasti dedicati a studenti con intolleranze o allergie al di fuori della semplice varietà della proposta già prevista al ristorante.</li>
          <li>Non è possibile farsi recapitare pacchi o posta personale all’indirizzo di ALMA per motivi di sicurezza, ma solo ed esclusivamente presso l’indirizzo della Biblioteca. </li>
          <li>I materiali didattici dei corsi di ALMA sono protette dalla legge n.633 del 22/04/1941 a tutela del diritto d’autore e dalla legge del 18/08/2000 contro la pirateria e contraffazione via internet.  Le slide sono fornite solamente per scopi didattici e non per essere utilizzate per altri scopi o progetti.  </li>
          <li>ALMA declina ogni responsabilità per l’utilizzo indebito delle slide e si riserva di emettere provvedimenti a carico di coloro che violino tale disposizione.</li>
          <li>A scopo formativo e/o all’interno del piano didattico gli allievi potranno essere coinvolti nell’organizzazione di manifestazioni enogastronomiche, anche di tipo promozionale, da svolgersi nella sede della scuola o presso terzi, il tutto senza che sia in alcun modo e minimamente mutata la natura del rapporto tra ALMA e lo studente.</li>
          <li>Gli allievi sono tenuti a rispettare l’ordine e la pulizia delle aree comuni (aule, spogliatoi, ristoranti, area cortilizia, bar e corridoi). </li>
          <li>Gli allievi sono tenuti ad un comportamento che non arrechi disturbo o sia causa di distrazione per gli altri allievi e per i docenti. In caso di comportamenti inadeguati all’ambiente scolastico e alla buona educazione, la Direzione si riserva l’adozione di provvedimenti quali la sospensione/espulsione dal corso nei casi più gravi quali utilizzo della violenza nei confronti dei colleghi di corso e dello staff di ALMA oppure in caso di furti accertati o detenzione e spaccio di sostanze stupefacenti. Gli studenti in fallo saranno ammoniti con una prima lettera di richiamo. Al secondo richiamo a danno di uno stesso studente, la Scuola si riserva la possibilità di interrompere il rapporto con lo stesso.</li>
          <li>Non saranno tollerati ammanchi di materiale e oggetti di proprietà della Scuola. Se dovessero verificarsi saranno presi gli opportuni provvedimenti.</li>
          <li>È assolutamente vietato fumare in tutti i locali della Scuola.</li>
          <li>È assolutamente vietato accedere alle aule con zaini, borse ed altri contenitori, che dovranno essere depositati all’interno degli armadietti in dotazione</li>
        </ol>
        <p></p>
        <h2>NORME COMPORTAMENTALI SU SALUTE E IGIENE</h2>
        <p>Poiché la tutela della salute è un diritto fondamentale dell’individuo ed interesse della collettività, ALMA si riserva la facoltà di:</p>
        <ol type="a">
          <li>Sottoporre e far compilare in forma riservata il questionario allergie e intolleranze alimentari il primo giorno di scuola.</li>
          <li>La visita medica è obbligatoria in caso di positività del questionario riservato al Medico Competente. </li>
          <li>Proporre allo studente una consulenza urgente presso il Medico Competente in caso di ragionevole dubbio da parte della scuola in merito alla presenza di patologie di interesse per la salute dello studente sul luogo di lavoro, del personale o di terzi all’interno della scuola. In caso di indisponibilità dello studente alla suddetta consulenza, ALMA, sentito il Medico Competente, si riserva di sospendere temporaneamente lo studente ed inviarlo a visita Medico Collegiale presso l’Organo di Vigilanza.</li>
          <li>La visita medica può essere richiesta dallo studente stesso presso il medico competente.</li>
          <li>Allontanare lo studente dalle lezioni in caso di malattia contagiosa dichiarata, in questo caso la frequenza potrà essere ripresa esclusivamente previa presentazione di documentazione medica che attesti la cessata contagiosità.</li>
          <li>L’allievo dichiara, con la sottoscrizione del presente Regolamento, di essere pienamente consapevole che durante lo svolgimento del corso potranno essere sottoposti ai partecipanti campioni di vino e/o di altre bevande di contenuto alcolico di modica quantità e gradazione, con la sola e specifica finalità di esercitare l’allievo, sotto la guida di un degustatore ufficiale, nella pratica della degustazione e di affinare la conoscenza delle caratteristiche organolettiche dei prodotti e le capacita sensoriali dell’allievo stesso e, in relazione a ciò, si  impegna, esonerando ALMA s.r.l. da qualsivoglia responsabilità, ad osservare le prescrizioni vigenti in materia di uso bevande alcoliche, ivi comprese quelle previste dal codice della strada.</li>
        </ol> 
      </div>
      <div class="col-11 mx-auto" v-else>
        <h2>BEHAVIOURAL NORMS: UNIFORM</h2>
        <p class="par">Attendance at ALMA courses requires an appropriate attire depending on the lessons and the type of course.</p>
        <p></p>
        <p><b>Cuisine and pastry courses</b></p>
        <ol type="a">
          <li>Maintain a dignified, professional appearance. You must keep your hair neat and tidy; if you have long hair, gather it up inside your toque. Male students must be clean-shaven.</li>
          <li>You must wash your uniform.</li>
          <li><u>All students must wear the ALMA uniform during all classes</u> (theory and practical); uniforms must be clean, neat and ironed. Students must not wear the uniform outside the school. Students must wear the uniform and change clothes in the changing rooms of the School.</li>
          <li>During the training lessons, you must always wear a toque or suitable white headgear.</li>
        </ol>
        <p></p>
        <h2>GENERAL RULES OF CONDUCT</h2>
        <ol type="a">
          <li>You must leave your assigned work area in the practical classroom clean and tidy. You must take great care of the equipment in the laboratories.</li>
          <li>You must keep to your lesson timetables: students must arrive in class 10 minutes before the lesson starts, to organize their work area. Lateness is not tolerated. If you miss the morning roll call, then you must go to the Student Office for permission to attend the afternoon lesson.</li>
          <li>You must not leave the room during a lesson without a very good reason or without the teacher’s permission. Comfort breaks are provided in the morning and afternoon.</li>
          <li>During the residential part of the course, the school will tolerate your missing up to 10% of the total number of hours. If the absence is attributed to illness, it is necessary to present a medical certificate by hand, at the Student Office to be reintegrated into the lessons. The medical documentation is necessary to justify the hours of absence and to communicate the student's suitability for attendance. Attendance is compulsory.</li>
          <li>Each week, all students must complete the questionnaires on ALMA’s online platform about the quality of the lessons. </li>
          <li>The School declines all responsibility for any theft. In the event of theft or vandalism, the School is authorized to summon students for the necessary verification of the event.</li>
          <li>Lost property: if you find any items left unattended or that are presumed lost, please bring them to the Student Office. If you lose any of your personal possessions, you can contact the Student Office.</li>
          <li>The school provides buffet meals in the restaurant. At the end of each meal, you must put used crockery in the containers provided and recycle or dispose of the various wastes appropriately.</li>
          <li>ALMA does not guarantee to provide special meals (besides the choice already offered at the restaurant) for students with food allergies or intolerances.</li>
          <li>ALMA employs all the raw materials used in traditional Italian cuisine; hence, we cannot guarantee that any religious beliefs or diets can be accommodated. </li>
          <li>for security reasons, it is not possible to have parcels or personal mail delivered to ALMA's address but only and exclusively to the Library address. </li>
          <li>The teaching materials of ALMA's courses are protected by the law n.633 of 22/04/1941 for the protection of copyright and by the law of 18/08/2000 against piracy and counterfeiting via internet.  The slides are provided for teaching purposes only and not to be used for other purposes or projects.</li>
          <li>ALMA declines any responsibility for the improper use of the slides and reserves the right to issue measures against those who violate this provision.</li>
          <li>Students may be involved in organizing food and wine events (e.g. dinners) outside school hours, including on Saturday and Sunday, for training purposes. Although you are not forced to attend those events, the school strongly encourages you to, as they are considered part of your training.</li>
          <li>Students must keep the communal areas (lecture rooms, changing rooms, restaurant and courtyard) clean and tidy. </li>
          <li>You must not disturb or distract the teachers or other students. The management may take action against any student behaving inconsiderately or inappropriately for an educational setting. That action may include suspension or expulsion from the course in the most serious cases including, but not limited to, violence against fellow students or ALMA staff, confirmed thefts, or possessing and pushing narcotics. If you commit an offence, you will receive a first written warning. After a second offence, the school may ask you to leave or may prevent you from participating in the traineeship phase.</li>
          <li>Pilferage of objects and material owned by the school will not be tolerated; if items go missing, appropriate measures will be taken.</li>
          <li>It is strictly forbidden to smoke anywhere on school premises</li>
          <li>It is strictly forbidden to enter the classrooms with rucksacks, bags or other containers; students must leave them in the lockers provided – only the ALMA bag with the utensil kit may be brought in the classroom.</li>
        </ol>
        <p></p>
        <h2>BEHAVIOURAL NORMS: HEALTH AND HYGIENE</h2>
        <p>Since the protection of health is a fundamental right of the individual and interest of the community, ALMA reserves the right to:</p>
        <ol type="a">
          <li>Submit and have filled in the food allergies and intolerances questionnaire in a confidential form on the first day of school.</li>
          <li>A medical examination is compulsory if the questionnaire reserved for the Competent Doctor is positive.</li>
          <li>Offer the student an urgent consultation with the Competent Doctor in case of reasonable doubt
            on the part of the school regarding the presence of pathologies of interest to the student&#39;s health in the
            workplace, staff or third parties within the school. In case of unavailability of the student to the above
            mentioned counselling, ALMA, after confrontation with the competent doctor, reserves the right to
            temporarily suspend the student and send him/her to the Supervisory Body for a visit by a Medical Board.</li>
          <li>Students may request a medical examination to the competent doctor</li>
          <li>In the case of a declared contagious illness, frequency to lessons must be suspended; in this case, the attendance can be resumed only upon presentation of medical documentation certifying they are no longer infectious.</li>
          <li>The student declares, by signing these Regulations, to be fully aware that during the course, samples of wine and/or other alcoholic beverages of modest quantity and alcohol content may be submitted to the participants, with the sole and specific purpose of exercising the student, under the guidance of an official taster, in the practice of tasting and to refine the knowledge of the organoleptic characteristics of the products and the sensory abilities of the student himself and, in relation to this, undertakes, exonerating ALMA s. r.l. from any responsibility, to comply with the regulations in force regarding the use of alcoholic beverages, including those provided for by the Highway Code.</li>
        </ol>
      </div>
    </div>
    

    <footerline></footerline>

  </div>
</template>

<script>
  // @ is an alias to /src
  import navbar from '@/components/navbar.vue'
  import herobig from '@/components/herobig.vue'

  export default {
    name: 'Home',
    components: {
      navbar,
      herobig,
    },
    data: function () {
      return {
      };
    },
  }
</script>