<template>
  <div class="home">
    <navbar v-bind:menulist="$store.state.mainmenu" ></navbar>
    <herobig v-bind:title="$t('title')"></herobig>

    <h2 align="center" class="welcome">{{$t('descrizioneAlloggio')}}</h2>

    <div class="row">
      <div class="col-10 mx-auto">
        <div v-if="$i18n.locale == 'it'">
          <u>Ogni appartamento è dotato di:</u>
          <ul>
            <li>soggiorno con televisione</li>
            <li>cucina completamente attrezzata di stoviglie ed elettrodomestici: forno, fornello, lavastoviglie, frigorifero, asse, ferro da stiro, stenditoio</li>
            <li>n° 1 o 2 bagni con vasca o doccia</li>
            <li>lavatrice/asciugatrice</li>
            <li>wi-fi</li>
            <li>aria condizionata</li>
            <li>riscaldamento</li>
          </ul>
          <br>
          Ogni posto letto è dotato di un coprimaterasso, un copriletto leggero, un cuscino.
          <br><br>
          <u>Occorre portare:</u>
          <ul>
            <li>biancheria da letto (lenzuola con angoli e lenzuola, cuscino se avete esigenze particolari, federa per cuscino, coperta pesante per il periodo invernale)</li>
            <li>biancheria da bagno (salviette, beauty)</li>
            <li>biancheria da cucina (tovaglia, canovacci)</li>
            <li>un phon per capelli</li>
          </ul>
        </div>

        <div v-else>
          ALMA accomodations provide:
          <ul>
            <li>Air conditioning, heating, hot and cold water, wi-fi and television channels;</li>
            <li>Fully functional living room with television, couch, coffee table, shelf, dining table and chairs;</li>
            <li>Fully equipped kitchen with plates, glasses, coffee cups and cutlery, cooking utensils, oven, stove, dishwasher, fridge and bins for recycling collection;</li>
            <li>Fully equipped laundry with washing machine, ironing board, iron and drying rack;</li>
            <li>The bathroom is equipped with shower area, toilet, bidet, hand wash sink, mirror, closets and a set of towels.</li>
            <li>The bedroom has bed with a mattress cover, a light bedspread, a pillow, a set of sheets. Also there is a small bedside table with lamp.</li>
          </ul>
          <br>
          Other necessary personal items like extra towels, extra sheets, winter blanket, kitchen towels, hair dryer as per individual necessities has to be brought by the individual candidate.
        </div>
      </div>
    </div>

    <footerline></footerline>

  </div>
</template>

<script>
  // @ is an alias to /src
  import navbar     from '@/components/navbar.vue'
  import herobig    from '@/components/herobig.vue'
  import footerline from '@/components/footerline.vue'

  export default {
    name: 'Home',
    components: {
      navbar,
      herobig,
      footerline
    },
    data: function() {
      return {
      }
    }
  }
</script>