<template>
  <div class="home">
    <navbar v-bind:menulist="$store.state.mainmenu"></navbar>
    <herobig v-bind:title="$t('title')"></herobig>

    <h2 align="center" class="welcome" style="padding-bottom:20px;">{{$t('subtitleVoucher')}}</h2>

    <div v-if="riscatta" class="row mx-auto d-flex justify-content-center">
      <!-- input voucher -->
      <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
        <form>
          <div class="form-group">
            <label for="username">Inserisci codice Voucher</label>
            <input v-model="vouchercode" type="text" class="form-control" />
          </div>
		</form>
      </div>
    </div>
    <div v-if="riscatta" class="row mx-auto d-flex justify-content-center" style="margin-bottom:90px;">
      <div class="col-lg-6 col-md-6 col-xs-12 ">
        <button v-on:click="searchVoucher()" class="btn btn-outline-success btn-lg btn-block">ATTIVA</button>
      </div>
    </div>
      <div v-if="attivato" class="row d-flex justify-content-center" >
        <div class="col-lg-6 col-md-6 col-xs-12">
        <p style="font-size:14px;">
          Il tuo voucher è attivo!<br>
          Sei ufficialmente iscritto al corso<br>
          {{oppRiscattata.Edizione_Corso__r.Corso__r.Name}} {{oppRiscattata.Numero_Edizione__c}}<br>
          Ti abbiamo mandato una conferma anche via mail.<br>
          <br>
          Nei prossimi giorni riceverai una mail con tutti i dettagli per partecipare.<br>
          <br>
          Grazie e buona formazione!<br>
          Lo staff ALMA<br>
        </p>
        </div>
         
    </div>
     <div v-if="attivato" class="row d-flex justify-content-center" style="margin-bottom:90px;">
      <div class="col-lg-6 col-md-6 col-xs-12 ">
      <button class="btn btn-primary" v-on:click="goto()">Area Studente</button>
      </div>
    </div>
    <signin v-if="login" v-on:logged="done($event)" v-on:signup="compilaform=true; login=false;"></signin>
    <formvoucher v-if="compilaform" v-on:done="done($event)" v-on:login="showlogin($event)"></formvoucher>

    <footerline></footerline>
  </div>
</template>

<script>
// @ is an alias to /src
import navbar from "@/components/navbar.vue";
import herobig from "@/components/herobig.vue";
import footerline from "@/components/footerline.vue";
import formvoucher from "@/components/formvoucher.vue";
import signin from '../components/signin.vue';

export default {
  name: "Home",
  components: {
    navbar,
    herobig,
    footerline,
    formvoucher,
    signin
  },
  data: function() {
    return {
      riscatta: false,
      attivato: false,
      vouchercode : '',
      logged: false,
      compilaform: false,
      login: false,
      oppRiscattata: {},
    };
  },
  mounted: function(){
    this.riscatta = true;
    this.login = false;
    this.compilaform = false;
    this.attivato = false;
    console.log(this.$store.state.connection.userdata);
    if(this.$store.state.connection.userdata && this.$store.state.connection.userdata !== '') this.logged = true;
    console.log(this.logged);
  },
  methods: {
    searchVoucher: function(){
      this.$store.dispatch('setCodiceVoucher', this.vouchercode)
      var self = this;
      var soql = 'select id,name,Voucher_Riscosso__c, IsClosed, IsWon, Edizione_Corso__r.Corso__r.Name, Numero_Edizione__c from Opportunity where Codice_Voucher__c =\''+this.vouchercode+'\' LIMIT 1';
      this.$store.dispatch('searchVoucher', soql).then(function(result){
        console.log(result);
        console.log(self.logged);
        if(result.length > 0){
          self.oppRiscattata = result[0];
          if(result[0].Voucher_Riscosso__c == true){
            alert('Voucher già riscosso');
          }else{
            if(result[0].IsClosed == true){
              alert('Voucher non trovato!');
            }else{
              if(self.logged && self.logged === true){
                console.log(self.logged);
                self.$store.dispatch('spostaOppty', self.vouchercode).then(function(){
                  self.attivato = true;
                  self.login = false;
                  self.compilaform = false;
                  self.riscatta = false;
                }).catch(function(err) {
                  console.log('problemi:')
                  console.log(err)
                  console.log('problemi:')
                  console.log(err.response.data)
                  alert('Errore nel riscatto del voucher');
                });
              }else{
                self.login = false;
                self.compilaform = true;
                self.attivato = false;
                self.riscatta = false;
              }
            }
          }
        }else{
          alert('Voucher non trovato!');
        }
      }).catch(function(err) {
        console.log('problemi:')
        console.log(err)
        console.log('problemi:')
        console.log(err.response.data)
          alert('Errore nella ricerca');
      });
    },
    showform: function(){
      this.login = false,
      this.compilaform = true;
      this.attivato = false;
      this.riscatta = false;
    },
    showlogin: function(event){
      console.log(event);
      this.login = true;
      this.compilaform = false;
      this.attivato = false;
      this.riscatta = false;
    },
    done: function(event){
      console.log(event);
      var self = this;
      //this.$store.dispatch('spostaOppty', self.vouchercode).then(function(){
        self.attivato = true;
        self.login = false;
        self.compilaform = false;
        self.riscatta = false;
      //}).catch(function(err) {
      //    console.log('problemi');
      //    console.log(err);
      //    alert('Errore nel riscatto del voucher');
      //});
    },
    goto: function(){
      this.$router.push({ name: 'Studente' });
    }
  }
};
</script>