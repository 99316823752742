<template>
  <div id="app">
    <install></install>
    <router-view/>
  </div>
</template>

<script>
  import install from '@/components/install'
  import numeral from 'numeral'
  import jwt from 'jsonwebtoken'

  export default {
    name: 'app',
    components: {
      install
    },
    watch: {
      // eslint-disable-next-line no-unused-vars
      $route(to, from) {
        console.log('cambio route')
        // console.log(from)
        // console.log(to)
        console.log(to.path)
        // eslint-disable-next-line no-undef
        gtag('event', 'screen_view', { 
          'screen_name': to.path, 
          'logged': this.$store.getters.logged, 
          'userCategory': this.$store.getters.logged && this.$store.state.userprofile.Categoria__c ? this.$store.state.userprofile.Categoria__c : 'unknown'
         })      
        // eslint-disable-next-line no-undef
        gtag('pageview', to.path, {  'send_to': 'gtag' })      
        // eslint-disable-next-line no-undef
        gtag('pageview', to.path, {  'send_to': 'webapp' })      
        // eslint-disable-next-line no-undef
        gtag('pageview', to.path, {  'send_to': 'aicod' })      
      }
    },
    created: function() {
      numeral.register('locale', 'it', {
          delimiters: { thousands: '.', decimal: ',' },
          currency: { symbol: '€' }
      })
      
      numeral.locale('it')
      this.$i18n.locale = 'it'

      this.$store.commit('SET_MAINMENU', [
          { title: 'HOME',             path: ''       },
          { title: 'INFO GENERALI',    path: 'Info'       },
          { title: 'AREA CANDIDATURA', path: 'Candidati'  },
          { title: 'MY ALMA LIFE',     path: 'MyAlmaLife' },
          //{ title: 'ASSISTENZA',       path: 'Interventi' } // path: 'Assistenza'
          //{title: 'SHOP',             path: 'EcommerceHome'}
      ])

      if(this.$route.query.user) {
        console.log('mounted user docente:', this.$route.query.user)
        this.$store.commit('RESET_USER_DATA')
        let user = jwt.decode(this.$route.query.user)
        console.log('userString',user)
        let connection = {
          username: user.username,
          userdata: user.token
        }
        this.$store.commit('SET_CONNECTION', connection)
        console.log('connection',this.$store.state.connection)

        this.$store.dispatch('loginbyid').then(() => {
          this.$store.dispatch('getProfile').then(() => {})
        })

      } else {
        this.$store.commit('GET_USER_DATA')

        const lang = navigator.language

        if (lang.substr(0,2) == 'it')
          this.$i18n.locale = 'it'
        else
          this.$i18n.locale = 'en'

        this.$store.dispatch('loginbyid').then(() => {
          this.$store.dispatch('getProfile').then(() => {
            this.$store.dispatch('getCampi', [ 'Lead', 'Account', 'Opportunity' ])
            if (this.$store.state.userprofile.Lingua__c) {
              console.log('set personal language to: ' + this.$store.state.userprofile.Lingua__c)
              this.$i18n.locale = this.$store.state.userprofile.Lingua__c
            }

            if (this.$store.getters.studente) {
              console.log('get housing')
              this.$store.dispatch('getHousing')
            }
            this.$store.dispatch('getOrdiniEcommercePronti')
          })
        })
      }
    },
    mounted: function() {
      // eslint-disable-next-line no-undef
      gtag('event', 'screen_view', { 
        'screen_name': this.$route.path, 
        'logged': this.$store.getters.logged, 
        'userCategory': this.$store.getters.logged && this.$store.state.userprofile.Categoria__c ? this.$store.state.userprofile.Categoria__c : 'unknown'
      })  
    }
  }
</script>

<style>
</style>