<template>
    <div class="row">
     <div class="col-lg-12 col-md-11 col-xs-12 ">
       <div>
         <span class="col-12 font-weight-bold">{{$t('Periodo')}}:</span>
      <select class="custom-select custom-select-sm mb-1 w-50" id="inputGroupSelect01" size="1"
        v-on:change="changePer" v-model="filtroSel">
        <option selected value="">{{$t('Seleziona')}}</option>
        <option v-for="ma in mesiAnno" v-bind:key="ma.val" v-bind:value="ma">{{ma.val}}</option>
      </select>
       </div>
       <h2 class="mt-3">{{$t('EsamiDisponibili')}}</h2>
       <div class="table-responsive-sm">
        <table class="table">
          <thead class="bg-alma text-white">
            <tr>
              <th scope="col">{{$t('DataInizio')}}</th>
              <th scope="col">{{$t('DataFine')}}</th>
              <th scope="col">{{$t('aperturaIscr')}}</th>
              <th scope="col">{{$t('TermineIscr')}}</th>
              <th scope="col">{{$t('NomeEsame')}}</th>
              <th scope="col">{{$t('PostiDisponibili')}}</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(e, index) in showEsami" >
            <tr v-bind:key="e.Id">
              <td style="padding-bottom: 0px">{{e.dEvento}} {{e.Ora_Inizio__c}} - {{e.Ora_Fine__c}}</td>
              <td>{{e.dFine}}</td>
              <td style="padding-bottom: 0px">{{e.aperturaPren}}</td>
              <td style="padding-bottom: 0px">{{e.entro}}</td>
              <td style="padding-bottom: 0px" v-if="e.Link__c"><a v-on:click="gotoLink(e)" target="_blank"><u><i>{{e.Nome_Evento__c}}</i></u></a></td>
              <td style="padding-bottom: 0px" v-else>{{e.Nome_Evento__c}}</td>
              <td style="padding-bottom: 0px">{{e.posti}}</td>
              <td style="padding-bottom: 0px">
                <button type="button" v-bind:class="e.stato == 'Partecipo'?'btn btn-info':e.stato == 'Completo'?'btn btn-light':'btn btn-danger'" 
                  v-bind:disabled="statodis(e)" v-on:click="iscrivimiOannulla(index)">{{e.stato}}</button>
              </td>
            </tr>
            <tr v-if="e.Descrizione__c" v-bind:key="index"><td style="border: none; padding: 0px 0px 12px 12px" colspan="6"><i>{{e.Descrizione__c}}</i></td></tr>
              
            </template>
          </tbody>
        </table>
    </div>
    
     </div>
     </div>
    </template>
    
    
    <script>
      export default {
        name: 'tabs',
        props: {
          tabs: Array
        },
        data: function () {
          return {
            show: false,
            activeTab: 0,
            esami:[],
            showEsami:[],
            mesiAnno:[],
            filtroSel: {},
            busy: false
          }
        },
        mounted: function(){
          var self = this;
          var options = { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric' };
          var options2 = { month: 'long', year: 'numeric'};
          var options3 = { weekday: 'short' };
          // let idOppty=this.$store.state.idOppty;
          var idOppty = this.$store.state.opptyPrenot.Id
          if (this.$store.state.idOpptyTesi) idOppty=this.$store.state.idOpptyTesi;
          var accountId = this.$store.state.userprofile.Id

          this.$store.dispatch('getEventi',{tipo:'Sessione Esami', data: '',oppty: idOppty, isRipescato: this.$store.state.opptyPrenot.isStudenteRipescabile__c})
          .then(function(resp){
            console.log('resp getEventi', resp)
            self.$store.dispatch('getPrenotazione', {idAccount: accountId, tipo: 'Sessione Esami'}) //{ piatto: piatto, ingredienti: ingatt }
            .then(function(data){
              console.log('data getPrenotazione', data)
              resp.forEach(element => { // per ogni calendario
                console.log('calendario id: '+ element.Id)
                var dataI = self.$i18n.locale=='it'?new Date(element.Data__c).toLocaleDateString("it-IT", options2):new Date(element.Data__c).toLocaleDateString("en-US", options2);
                dataI = dataI.charAt(0).toUpperCase() + dataI.slice(1);
                var row = {val: dataI, mese: new Date(element.Data__c).getMonth(), anno: new Date(element.Data__c).getFullYear()};
                row.ord = new Date(row.anno+'-'+row.mese+'-01');
                var exists = self.mesiAnno.some(function(field) {
                  return field.val === row.val;
                });
                if(!exists)self.mesiAnno.push(row);
    
                if(element.Capienza_MAX__c == element.Occupazione_attuale__c) element.stato = 'Completo';
                else element.stato='Partecipo';
                element.posti = element.Occupazione_attuale__c? parseInt(element.Capienza_MAX__c - element.Occupazione_attuale__c) : parseInt(element.Capienza_MAX__c);
                data.forEach(function(d){
                  if(d.Calendario__c == element.Id){
                    element.stato = 'Annulla';
                  }
                  if( element.Gruppo_Evento__c && d.Calendario__r.Gruppo_Evento__c == element.Gruppo_Evento__c) element.eventoBloc=true;
                });
                if(element.Ora_Inizio__c) element.Ora_Inizio__c = element.Ora_Inizio__c.substring(0, 5);
                if(element.Ora_Fine__c) element.Ora_Fine__c = element.Ora_Fine__c.substring(0, 5);
                
                if(element.Data__c){
                  var dataEv  = new Date(element.Data__c);
                  element.dEvento = self.$i18n.locale=='it'?dataEv.toLocaleDateString("it-IT", options):dataEv.toLocaleDateString("en-US", options);
                  var arr = element.dEvento.charAt(0).toUpperCase() + element.dEvento.slice(1);
                  element.dEvento=arr;
                  if(!element.Chiusura_Prenotazioni__c)element.Chiusura_Prenotazioni__c = element.Data__c;
                }
                if(element.Data_Fine__c){
                  var dataFin  = new Date(element.Data_Fine__c);
                  element.dFine = self.$i18n.locale=='it'?dataFin.toLocaleDateString("it-IT", options):dataFin.toLocaleDateString("en-US", options);
                  var arr1 = element.dFine.charAt(0).toUpperCase() + element.dFine.slice(1);
                  element.dFine=arr1;
                }
                if(element.Chiusura_Prenotazioni__c){
              var dataEntr  = new Date(element.Chiusura_Prenotazioni__c);
              element.entro = self.$i18n.locale=='it'?dataEntr.toLocaleDateString("dataEntr", options):dataEntr.toLocaleDateString("en-US", options);
              var arr2 = element.entro.charAt(0).toUpperCase() + element.entro.slice(1);
              element.entro=arr2 +' '+ '00:00'
            }
            if(element.Apertura_Prenotazioni__c) {
              var giornoOra = new Date(element.Apertura_Prenotazioni__c)
              element['aperturaPren'] = self.$i18n.locale=='it'? giornoOra.toLocaleDateString("dataEntr", options3) : giornoOra.toLocaleDateString("en-US", options3)+' '
              var arr3 = element.aperturaPren.charAt(0).toUpperCase()  + element.aperturaPren.slice(1)
              element.aperturaPren = arr3 +' '+ ('00' + giornoOra.getDate()).slice(-2)+'/'
                                    +('00' + (giornoOra.getMonth()+1)).slice(-2)+'/'
                                    +giornoOra.getFullYear()+' '
                                    +('00' + giornoOra.getHours()).slice(-2)+":"
                                    +('00' + giornoOra.getMinutes()).slice(-2)
            }
                self.esami.push(element);
                //self.showEsami.push(element);
              });
              self.esami.sort(self.compare2);
              self.mesiAnno.sort(self.compare);
            });
          });
        },
        computed: {
        },
        methods: {
          compare: function( a, b ) {
            if ( a.ord < b.ord ) return -1
            if ( a.ord > b.ord ) return 1
            return 0;
          },
          compare2: function( a, b ) {
            var dateA = new Date(a.dEvento);
            var dateB = new Date(b.dEvento);
            return dateA - dateB;
          },
          goto: function (item) {
            this.activeTab = item;
            this.$emit('change', item);
          },
          iscrivimiOannulla: function(index){
            this.busy = true;
            var esame = this.showEsami[index];
            console.log(esame.Id);
            var self = this;
    
            if(esame.eventoBloc && esame.stato != 'Annulla'){
              alert('Questo esame non è compatibile con un’altra attività cui ti sei già iscritto');
              this.busy = false;
              return;
            }
            if(esame.Obbligatorio__c && esame.stato == 'Annulla'){
              alert('Contatta l’organizzazione: esamifinali@scuolacucina.it');
              this.busy = false;
              return;
            }
            if(esame.stato == 'Partecipo'){
              if (confirm('Confermi prenotazione?')) {
                console.log('Partecipo --> Confermi prenotazione? Sì')

                var idOppty = ''
                var idCorso = ''
                if(this.$store.state.opptyPrenot && this.$store.state.opptyPrenot.Id) idOppty = this.$store.state.opptyPrenot.Id
                if(this.$store.state.opptyPrenot && this.$store.state.opptyPrenot.Edizione_Corso__c && this.$store.state.opptyPrenot.Edizione_Corso__r.Corso__c) {
                  idCorso = this.$store.state.opptyPrenot.Edizione_Corso__r.Corso__c
                }
                // if(this.$store.state.idOppty != '') idOppty = this.$store.state.idOppty
                // else if (this.$store.state.idOpptyTesi != '') idOppty = this.$store.state.idOpptyTesi
                console.log('idOppty', idOppty)
                console.log('idCorso', idCorso)

                this.$store.dispatch('createPrenotazione', {idEvento: esame.Id, idOppty: idOppty, idCorso: idCorso, isRipescato: this.$store.state.opptyPrenot.isStudenteRipescabile__c})
                .then(function(resp) {
                  self.busy = false;
                  alert(resp);
                  window.location.reload();
                }).catch(function(err){
                  self.busy = false;
                  console.log('problemi', err)
                });
              }
            }else{
              if (confirm('Confermi annullamento?')) {
                this.$store.dispatch('annullaPrenotazione', esame.Id).then(function(resp) {
                  self.busy = false;
                  alert(resp);
                  window.location.reload();
                }).catch(function(err){
                  self.busy = false;
                  console.log('problemi', err)
                });
              }else{
                  self.busy = false;
              }
            }
          },
          statodis: function(el) {
            if(el.Chiusura_Prenotazioni__c != undefined && (new Date()).getTime() > new Date(el.Chiusura_Prenotazioni__c).getTime()) return true;
            if(el.Apertura_Prenotazioni__c != undefined && new Date(el.Apertura_Prenotazioni__c) > new Date()) return true;
            if(el.stato == 'Completo') return true;
            if(this.busy)return true;
            return false;
          },
          gotoLink: function(el){
            window.open(el.Link__c, '_blank');
          },
          changePer: function(){
            console.log(this.filtroSel);
            var r = this.filtroSel;
            var self = this;
            if(r){
                console.log(JSON.stringify(r.val));
                this.showEsami = [];
                this.esami.forEach(function(e){
                    console.log(e);
                    var mese, anno;
                    mese = new Date(e.Data__c).getMonth();
                    anno = new Date(e.Data__c).getFullYear();
                    if(mese == r.mese && anno == r.anno){
                    self.showEsami.push(e);
                    }
                })
            }else{
              this.showEsami = [];
            }
          }
        }
      }
    </script>
    
    <style scoped>
    </style>
    