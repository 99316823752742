<template>
  <div class="home">
    <navbar v-bind:menulist="$store.state.mainmenu" ></navbar>
    <herobig v-bind:title="$t('title')"></herobig>

    <h2 class="welcome">{{$t('subtitleProfiloPersonale')}}</h2>

    <boxcontainer>

        <div class="row mx-auto">
          <div class="col">
          </div>
          <div class="col" style="text-align:center">
            <label class="control-label">{{$t('E-Mail')}}</label>
            <input
            v-model="$store.state.emailRecovery"
            maxlength="100"
            type="text"
            class="form-control"
            placeholder="Email"
            />
        <div style="margin-top: 15px">
          <span v-if="showerror && this.$i18n.locale=='it'" class="text-danger">User non trovato, contatta <a href="mailto:iscrizioni@scuolacucina.it" style="font-size:15px;padding-left:5px;">iscrizioni@scuolacucina.it</a></span>
          <span v-else-if="showerror && this.$i18n.locale=='en'" class="text-danger">User not found, contact <a href="mailto:iscrizioni@scuolacucina.it" style="font-size:15px;padding-left:5px;">iscrizioni@scuolacucina.it</a></span>
          <span v-if="showsuccess && this.$i18n.locale=='it'" class="text-success">Richiesta inviata! Riceverai una mail.</span>
          <span v-else-if="showsuccess && this.$i18n.locale=='en'" class="text-success">Request sent! Check your inbox.</span>
           </div>
            <button type="submit" class="btn btn-primary" style="margin-top: 15px" v-on:click.prevent="sendEmail">{{$t('Invia')}}</button>
          </div>
          <div class="col">
          </div>
        </div>

    </boxcontainer>

    <footerline></footerline>

  </div>
</template>

<script>
  // @ is an alias to /src
  import navbar from '@/components/navbar.vue'
  import herobig from '@/components/herobig.vue'
  import boxcontainer from '@/components/boxcontainer.vue'
  import footerline from '@/components/footerline.vue'

  export default {
    name: 'Home',
    components: {
      navbar,
      herobig,
      boxcontainer,
      footerline,
    },
    data: function () {
        return {
            showerror: false,
            showsuccess: false,
      };
    },
    methods: {
        sendEmail: function(){
          var self = this;
              this.$store.dispatch('doRecover')
              .then(function(resp) {
                  console.log(resp);
                  if(resp == 'no'){
                      self.showerror = true;
                      self.showsuccess = false;
                  }
                  if(resp == 'ok'){
                      self.showerror = false;
                      self.showsuccess = true;
                  }
              });
          }
    }
  }
</script>