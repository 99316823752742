<template>
  <div class="home">
    <navbar v-bind:menulist="$store.state.mainmenu" ></navbar>
    <herobig v-bind:title="$t('title')"></herobig>

    <h2 align="center" class="welcome">{{$t('subtitleRegolamento')}}</h2>

    <div class="row">
      <div class="mx-auto col-lg-3 col-xs-12 col-sm-12"></div>
        <div class="text-center col-md-3 col-lg-3 col-xs-12 col-sm-12" style="padding:10px;">
            <button class="btn btn-primary" v-on:click.prevent="goto()">{{$t('RegolamentoDidattica')}}</button>
      </div>

      <div class="text-center col-md-3 col-lg-3 col-xs-12 col-sm-12" style="padding:10px;">
        <!-- <a href="https://my.flipbookpdf.net/R5ukp"> -->
        <a v-if="$i18n.locale=='it'" href="https://my.flipbookpdf.net/R5ukp">
          <button class="btn btn-primary">{{$t('ProtocolloCovid')}}</button>
        </a>
        <a v-if="$i18n.locale=='en'" href="https://www.alma.scuolacucina.it/en/almas-protocol-safety-health-and-the-future/">
          <button class="btn btn-primary">{{$t('ProtocolloCovid')}}</button>
        </a>
      </div>
      <div class="mx-auto col-lg-3 col-xs-12 col-sm-12"></div>
    </div>
    

    <footerline></footerline>

  </div>
</template>

<script>
  // @ is an alias to /src
  import navbar from '@/components/navbar.vue'
  import herobig from '@/components/herobig.vue'

  export default {
    name: 'Home',
    components: {
      navbar,
      herobig,
    },
    data: function () {
      return {
      };
    },
    methods: {
      goto: function() {
          this.$router.push({ name: "RegolamentoAlma" });
      }
    }
  }
</script>