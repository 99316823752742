<template>
  <div class="home">
    <navbar v-bind:menulist="$store.state.mainmenu" ></navbar>
    <herobig v-bind:title="title"></herobig>

    <h2 class="welcome">Login Test Area: beware!</h2>

    <boxcontainer>


        <div class="row">

          <div class="col">
            <form>
              <div class="form-group">
                <label for="username">Username</label>
                <input v-model="username" type="text" class="form-control">
              </div>
              <div class="form-group">
                <label for="password">Password</label>
                <input v-model="password" type="password" class="form-control">
              </div>
              <div class="form-group">
                <label for="password">Session Token</label>
                <input v-bind:value="$store.state.connection.token" type="text" class="form-control">
              </div>

              <div class="form-group" v-if="$store.getters.logged">
                <label for="password">Userdata</label>
                <input v-bind:value="$store.state.connection.userdata" type="text" class="form-control">
              </div>

              <div class="form-group" v-if="$store.getters.logged">
                <label v-if="$store.getters.candidato">Candidato</label>&nbsp;
                <label v-if="$store.getters.studente">Studente</label>&nbsp;
                <label v-if="$store.getters.diplomato">Diplomato</label>
              </div>

              <button type="submit" class="btn btn-primary"  v-on:click.prevent="doLogin">Login</button> - - - 
              <button type="submit" class="btn btn-primary" v-if="$store.getters.logged" v-on:click.prevent="doLoginById">Relogin</button> - - - 
              <button type="submit" class="btn btn-primary"  v-on:click.prevent="doLogout">Logout</button>
            </form>
          </div>

          <div class="col">
            <form>
              <div class="form-group">
                <label for="username">Name</label>
                <input v-model="user.Name" type="text" class="form-control">
              </div>
              <div class="form-group">
                <label for="password">LastName</label>
                <input v-model="user.LastName" type="text" class="form-control">
              </div>
              <div class="form-group">
                <label for="password">FirstName</label>
                <input v-model="user.FirstName" type="text" class="form-control">
              </div>
              <div class="form-group">
                <label for="password">PersonEmail</label>
                <input v-model="user.PersonEmail" type="text" class="form-control">
              </div>
              <div class="form-group">
                <label for="password">PersonMobilePhone</label>
                <input v-model="user.PersonMobilePhone" type="text" class="form-control">
              </div>
              <button type="submit" class="btn btn-primary"  v-on:click.prevent="doGetProfile">Get Profile</button>
              - - -
              <button type="submit" class="btn btn-danger"  v-on:click.prevent="doRegister">Register</button>
            </form>
          </div>
          
        </div>

        <div class="row">
          <div class="col">
            <button type="submit" class="btn btn-primary"  v-on:click.prevent="doGetCandidatura">Get Candidatura</button>
            <textarea v-model="candidatura"></textarea>
          </div>
        </div>

    </boxcontainer>
    <footerline></footerline>

  </div>
</template>

<script>
  // @ is an alias to /src
  import navbar from '@/components/navbar.vue'
  import herobig from '@/components/herobig.vue'
  import boxcontainer from '@/components/boxcontainer.vue'
  import footerline from '@/components/footerline.vue'

  export default {
    name: 'Home',
    components: {
      navbar,
      herobig,
      boxcontainer,
      footerline
    },
    data: function () {
      return {
        title: 'La Scuola Internazionale di Cucina Italiana',
        boxes: [],
        username:'ptorric2',
        password:'segreto',
        user: { 
          FirstName: this.$store.state.userprofile.FirstName,
          Id: "",
          IsPersonAccount: '',
          LastName: this.$store.state.userprofile.LastName,
          Name: "xxxx",
          PersonEmail: this.$store.state.userprofile.PersonEmail,
          PersonMobilePhone: this.$store.state.userprofile.PersonMobilePhone
        },
        candidatura:""
      };
    },
    created: function() {
    },
    methods: {
      doGetCandidatura: function() {
        this.$store.commit('RESET_USER_DATA');
      },
      doLogout: function() {
        this.$store.commit('RESET_USER_DATA');
      },
      doLogin: function() {
        var data={ username: this.username, password: this.password };
        this.$store.dispatch('login', data);
      },
      doLoginById: function() {
        this.$store.dispatch('loginbyid');
      },
      doGetProfile: function() {
        var data={ username: this.username, crypt: this.$store.state.connection.userdata };
        var self=this;
        this.$store.dispatch('getProfile', data).then(function() {
          self.user.FirstName=self.$store.state.userprofile.FirstName;
          self.user.LastName=self.$store.state.userprofile.LastName;
          self.user.PersonEmail=self.$store.state.userprofile.PersonEmail;
          self.user.PersonMobilePhone=self.$store.state.userprofile.PersonMobilePhone;
        });
      },
      doRegister: function() {
        var data=this.user;
        data.username=this.username;
        data.password=this.password;

        this.$store.dispatch('register', data);
      }
    }
  }
</script>