<template>
  <div class="home">
    <navbar v-bind:menulist="$store.state.mainmenu" ></navbar>
    <herobig v-bind:title="$t('title')"></herobig>

    <h2 align="center" class="welcome">{{$t('subtitleChiediAssistenza')}}</h2>

    <div class="row" v-if="$i18n.locale=='it'">
      <div class="col-10 mx-auto" style="padding-top:20px;">

        <p>{{$t('messaggioChiediAssistenza')}}</p>
        
        <p style="font-size:18px;">
          <img src="icons/mail.png" width="30">
           <a href="mailto:iscrizioni@scuolacucina.it" style="font-size:18px;padding-left:5px;">iscrizioni@scuolacucina.it</a> 
       </p> 
        
      
       <p style="font-size:18px;">
         <img src="icons/phone.png" width="30">
           <a href="tel:+390521525228" style="font-size:18px;padding-left:5px;">+39 0521 525228</a>  
       </p>
      
      </div>
 
    </div>
    <div class="row" v-if="$i18n.locale=='en'">
      <div class="col-10 mx-auto" style="padding-top:20px;">

        <p>{{$t('messaggioChiediAssistenza')}}</p>
       <p style="font-size:18px;">
          <img src="icons/mail.png" width="30">
           <!-- <a href="mailto:studentoffice@scuolacucina.it" style="font-size:18px;padding-left:5px;">studentoffice@scuolacucina.it </a>  -->
           <a href="mailto:enrolment@scuolacucina.it" style="font-size:18px;padding-left:5px;">enrolment@scuolacucina.it</a> 
       </p>
      
      </div>
 
    </div>
    <footerline></footerline>

  </div>
</template>

<script>
  // @ is an alias to /src
  import navbar from '@/components/navbar.vue'
  import herobig from '@/components/herobig.vue'

  export default {
    name: 'Home',
    components: {
      navbar,
      herobig,
    },
    data: function () {
      return {
      };
    },
  }
</script>