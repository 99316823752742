<template>
    <div class="home">
        <navbar v-bind:menulist="$store.state.mainmenu" ></navbar>
        <herobig v-bind:title="$t('title')"></herobig>

        <h2 align="center" class="welcome">{{$t('descrizioneAlloggio')}}</h2>

        <div class="row d-flex justify-content-center">
            <div class="text-center col-md-3 col-lg-3 col-xs-12 col-sm-12 p-4">
                <a href="https://almascuola.my.salesforce.com/sfc/p/1t000000ss9x/a/7T000000IBIJ/MSOsyiw5podiLS3JoS9O1zK2mQJOpOPItiec0bkGSJ0">
                    <button class="btn btn-primary" type="button">SELECTIVE WASTE COLLECTION</button>
                </a>
            </div>
            <div class="text-center col-md-3 col-lg-3 col-xs-12 col-sm-12 p-4">
                <a href="https://almascuola.my.salesforce.com/sfc/p/1t000000ss9x/a/7T000000IBIO/RakeIwcfVcRMiBGh49gZELdvPI1oKjNitqO6GKpXdvc">
                    <button class="btn btn-primary" type="button">SPECIFICATION OF BINS & BAGS FOR WASTE COLLECTION</button>
                </a>
            </div>
        </div>

        <footerline></footerline>
    </div>
</template>

<script>
    // @ is an alias to /src
    import navbar     from '@/components/navbar.vue'
    import herobig    from '@/components/herobig.vue'
    import footerline from '@/components/footerline.vue'

    export default {
        name: 'Home',
        components: {
            navbar,
            herobig,
            footerline
        },
        data: function() {
            return {
            }
        }
    }
</script>