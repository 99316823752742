<template>
  <div class="home">
    <navbar :menulist="$store.state.mainmenu"></navbar>
    <herobig :title="$t('title')"></herobig>

    <h2 class="welcome">{{$t('subtitleHousing')}}</h2>

    <boxcontainer>
      <div class="row">
        <div :class="[' mx-auto col-lg-'+box.w]" v-for="box in boxes" :key="box.id">
          <box
            :imgname="box.imgname"
            :alttext="$t('boxesHousing'+box.id+'.alttext')"
            :title="$t('boxesHousing'+box.id+'.title')"
            :menulist="box.menulist"
            :item="box.item"
            :extlink="box.extlink"
            :params="box.params"
          >
          </box>
        </div>
      </div>

    </boxcontainer>

    <footerline></footerline>
  </div>
</template>

<script>
  // @ is an alias to /src
  import navbar       from '@/components/navbar.vue'
  import herobig      from '@/components/herobig.vue'
  import box          from '@/components/box.vue'
  import boxcontainer from '@/components/boxcontainer.vue'
  import footerline   from '@/components/footerline.vue'

  export default {
    name: 'Housing',
    components: {
      navbar,
      herobig,
      box,
      boxcontainer,
      footerline
    },
    data: function () {
      return {
        boxes: []
      }
    },
    mounted: function() {
      // console.log('studente = ' + this.$store.getters.housing)

      if (!this.$store.getters.housing) {
        this.$router.push({ name: 'Home' })
        return
      }

      var w = 12/4
      this.boxes.splice(0, this.boxes.length)

      var indirizzo = [], mapsSearch = ''

      if (this.$store.state.opptyHousing) {
        var h = this.$store.state.opptyHousing
        console.log(h)

        if(h.Appartamento__r) {
          indirizzo.push(h.Appartamento__r.Name)
          indirizzo.push(h.Appartamento__r.Indirizzo__c)
          mapsSearch = h.Appartamento__r.Indirizzo__c.replace(' ', '+')

          if (h.Data_ingresso_Housing_formatted__c) indirizzo.push('Check In: '  + h.Data_ingresso_Housing_formatted__c)
          if (h.Ora_Check_In__c)  indirizzo.push('Ora: ' + h.Ora_Check_In__c)
          if (h.Data_rilascio_Housing_formatted__c) indirizzo.push('Check Out: ' + h.Data_rilascio_Housing_formatted__c)
          if (h.Ora_Check_Out__c) indirizzo.push('Ora: ' + h.Ora_Check_Out__c)
        }
      }

      this.boxes.push({
        id: 1,
        w: w,
        imgname: 'img/housing.jpg',
        menulist: indirizzo,
        extlink: `https://www.google.com/maps/place/${mapsSearch}/`
      })

      this.boxes.push({
        id: 2,
        w: w,
        imgname: 'img/regolamento.jpg',
        item: { title:'RegolamentoHousingGen', path:'RegolamentoHousingGen' }
      })

      this.boxes.push({
        id: 3,
        w: w,
        imgname: 'img/segreteria.jpg',
        item: { title:'Interventi', path:'Interventi', params: { from: 'Housing' }}
      })

      this.boxes.push({
        id: 4,
        w: w,
        imgname: 'img/infoemergenza.jpg',
        extlink: this.$i18n.locale == 'it' ? 'https://www.alma.scuolacucina.it/numeri-utili/' : 'https://almascuola.my.salesforce.com/sfc/p/1t000000ss9x/a/7T000000IBIP/rMQsaLz46cqerfVEAtWGqJxXb80TJpkcUCiOuUVfJVQ'
      })
    }
  }
</script>