<template>
  <div class="home">
    <navbar v-bind:menulist="$store.state.mainmenu" ></navbar>
    <herobig v-bind:title="$t('title')"></herobig>

    <h2 class="welcome" v-if="!reg">Scansiona il qrcode</h2>
    <h2 class="welcome" v-if="reg">{{ msg }}</h2>

    <boxcontainer v-if="$store.getters.enablePresenze">
      <div v-if="!cameraok">
        Videocamera in corso di attivazione...
      </div>
      <div v-if="detected && !reg">
        Presenza registrata: Aula {{ aulaId }}<br/>
        Procedura terminata, chiusura in corso...
      </div>
      <qrcode-stream v-on:detect="onDetect" v-on:init="initCamera" v-if="!detected" ></qrcode-stream>
    </boxcontainer>

    <footerline></footerline>

  </div>
</template>

<script>
  // @ is an alias to /src
  import navbar from '@/components/navbar.vue'
  import herobig from '@/components/herobig.vue'
  import boxcontainer from '@/components/boxcontainer.vue'
  import footerline from '@/components/footerline.vue'
  import { QrcodeStream } from 'vue-qrcode-reader'
  import apiclient from '../apiclient.js'
  //import moment from 'moment'
  
  export default {
    mounted(){
      this.checkLastRecordedCheckin();
    },
    name: 'Presenze',
    components: {
      navbar,
      herobig,
      boxcontainer,
      footerline,
      QrcodeStream
    },
    computed: {

    },
    data: function () {
      return {
        aulaId:'',
        cameraok:false,
        detected:false,
        detectedcode:'',
        msg:'',
        reg: false
      };
    },

    methods: {
      initCamera: function(camera) {
        console.log('initCamera');

        camera.then( () =>{
          this.cameraok=true;
        }).catch( (error) => {
          console.error(error);
          alert(error.name);
        })
      },
      onDetect: function(e) {
        console.log('detected',e);
        e.then( resp => {
          console.log(resp);
          this.registraPresenza(resp.content);
        })
      },
      registraPresenza: function(code) {
        let code2=code.split(';');
        if (code2.length>1) { // otp;aula
          this.detectedcode=code;
          this.aulaId=code2[1];
          this.doRegistra(code2[1], code2[0]);
        }
      },

      doRegistra:function(aulaId, otp){
        var self=this;
        var idOppty = ''
        if(this.$store.state.opptyCorso) idOppty = this.$store.state.opptyCorso.Id
        var data = {
          callname:'saveCheckin', 
          username: this.$store.state.connection.username, 
          crypt: this.$store.state.connection.userdata, 
          aulaId: aulaId, 
          totp: otp,
          idOppty: idOppty
        };
        apiclient.getcandidatura( this.$store.state.connection, data).then( function(resp) {
          console.log(resp);
          self.detected=true;
          setTimeout(() => {
            window.location='/#/didattica';
          }, 5000)
        });
      },

      checkLastRecordedCheckin:function(){
        console.log("STO CONTROLLANDO L'ULTIMA REGISTRAZIONE")
        var self=this;
        //var data={callname:'lastRecordedCheckin', username: this.$store.state.connection.username, crypt: this.$store.state.connection.userdata};
        //apiclient.getcandidatura( this.$store.state.connection, data).then( function(resp) {
        this.$store.dispatch('lastRecordedCheckin').then((resp) => {  
          console.log(resp);
        /*
          var last = resp[resp.length-1];
          var tmp = last['Data_presenza__c'];
          var data = moment(tmp);
          tmp = moment();
          const tempodopo = data.add(1, 'hours');

          if(!tmp.isAfter(tempodopo)){
            self.reg = true;
            self.cameraok = false;
            self.msg = "Ti sei già registrato per questa ora";
            self.detected=true;

          }*/

          if(resp == 'registrato'){
            self.reg = true;
            self.cameraok = false;
            self.msg = "Ti sei già registrato per questa ora";
            self.detected= true;
          }
        });
      },

    }
  }
</script>

<style scoped>

</style>