import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import './registerServiceWorker'
import i18n from './i18n'
import './assets/stileAlma.css'
Vue.use(i18n);

Vue.config.productionTip = false

var v=new Vue({
  i18n,
  store,
  router,
  render: h => h(App)
}).$mount('#app')

window.myapp=v;