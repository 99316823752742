<template>
    <div class="home">
        <navbar :menulist="$store.state.mainmenu"></navbar>
        <herobig :title="$t('title')"></herobig>
  
        <h2 class="welcome">{{$t('subtitleSponsor')}}</h2>
  
        <boxcontainer>
            <div class="row">
                <div :class="[' mx-auto col-lg-'+box.w]" v-for="box in boxes" :key="box.id">
                    <box 
                        :imgname="box.imgname" 
                        :alttext="$t('boxesSponsor'+box.id+'.alttext')"
                        :title="$t('boxesSponsor'+box.id+'.title')" 
                        :menulist="box.menulist"
                        :item="box.item"
                        :extlink="$t('boxesSponsor'+box.id+'.extlink')"
                    >
                    </box>
                </div>
            </div>
        </boxcontainer>

        <footerline></footerline>
    </div>
</template>
  
<script>
    // @ is an alias to /src
    import navbar       from '@/components/navbar.vue'
    import herobig      from '@/components/herobig.vue'
    import box          from '@/components/box.vue'
    import boxcontainer from '@/components/boxcontainer.vue'
    import footerline   from '@/components/footerline.vue'
  
    export default {
        name: 'Area Sponsor',
        components: {
            navbar,
            herobig,
            box,
            boxcontainer,
            footerline
        },
        data: function () {
            return {
                boxes: []
            }
        },
        mounted: function() {
            var w = 12 / 3

            this.boxes.splice(0, this.boxes.length)
            
            this.boxes.push({
                id: 1,
                w: w,
                imgname: 'img/sponsorricette.jpg'
            })

            this.boxes.push({
                id: 2,
                w: w,
                imgname: 'img/sponsorwebinar.jpg'
            })

            this.boxes.push({
                id: 3,
                w: w,
                imgname: 'img/sponsornews.jpg',
            })
        }
    }
</script>