<template>

  <div class="row">
    <div class="col">

       <div class="row">
        <div class="col-5 offset-1">
          <div class="form-group ">
            <label class="control-label">{{$t('E-Mail')}} <span class="text-danger">*</span></label>
            <input v-bind:class="{'is-invalid': !user.username || !validateEmail(user.username) }" v-model="user.username" maxlength="100" type="text" class="form-control" :placeholder="$t('Inserisciemail')" />
          </div>
        </div>
        <div class="col-5">
          <div class="form-group ">
            <label class="control-label">PASSWORD <span class="text-danger">*</span></label>
            <input v-bind:class="{'is-invalid': !user.password}" v-model="user.password" maxlength="100" type="password" class="form-control" placeholder="Password" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <hr />
        </div>
      </div>

      <div class="row">
        <div class="col-5 offset-1">
          <div class="form-group ">
            <label class="control-label">{{$t('Nome')}}</label>
            <input v-model="user.FirstName" maxlength="100" type="text" class="form-control" :placeholder="$t('Nome')" />
          </div>
        </div>
        <div class="col-5">
          <div class="form-group">
            <label class="control-label">{{$t('Cognome')}}</label>
            <input v-model="user.LastName" maxlength="100" type="text" class="form-control" :placeholder="$t('Cognome')" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-5 offset-1">
          <div class="form-group ">
            <legend class="col-form-label">{{$t('Sesso')}}</legend>
            <div class="form-check form-check-inline">
              <input v-model="user.Sesso__c" class="form-check-input" type="radio" name="F" value="F">
              <label class="form-check-label"> F </label>
            </div>
            <div class="form-check form-check-inline">
              <input v-model="user.Sesso__c" class="form-check-input" type="radio" name="M" value="M">
              <label class="form-check-label"> M </label>
            </div>
          </div>
        </div>
        <div class="col-5">
          <div class="form-group ">
            <label class="control-label">{{$t('DatadiNascita')}}</label>
            <input v-model="user.Data_Nascita__c" type="date" class="form-control" />
          </div>
        </div>
      </div>
     

      <div class="row">
        <div class="col-5 offset-1">
          <div class="form-group ">
            <label class="control-label">{{$t('E-Mail(ripetere)')}}<span class="text-danger">*</span></label>
            <input v-bind:class="{'is-invalid': !user.PersonEmail || !validateEmail(user.username) }" v-model="user.PersonEmail" maxlength="100" type="email" class="form-control" placeholder="Email" />
          </div>
        </div>
        <div class="col-5">
          <div class="form-group  ">
            <label class="control-label">{{$t('Telefono')}}</label>
            <input v-model="user.PersonMobilePhone" maxlength="100" type="tel" class="form-control" :placeholder="$t('Telefono')" />
          </div>
        </div>
      </div>

      <div class="row align-items-end">
        <div class="col-5 offset-1">
          <div class="form-group">
            <label class="control-label">{{$t('IdentificaRuolo')}} <span class="text-danger">*</span></label>
            <select v-bind:class="{'is-invalid': !user.Ruolo__c}" v-model="user.Ruolo__c" class="form-control" :title="$t('Scegliunaopzione')">
              <option value="">- -</option>
              <option value="1">{{$t('StudenteIPSAR')}}</option>
              <option value="2">{{$t('Genitore')}}</option>
              <option value="3">{{$t('ProfessionistaHORECA')}}</option>
              <option value="4">{{$t('DocenteIPSAR')}}</option>
              <option value="5">{{$t('CandidatoAlma')}} *</option>
              <option value="6">{{$t('StudenteAlma')}} *</option>
              <option value="7">{{$t('DiplomatoAlma')}} *</option>
            </select>
          </div>
        </div>
        <div class="col-5">
          <div class="form-group">
            <span v-if="user.Ruolo__c>4 && this.$i18n.locale=='it'">* Richiede qualche giorno per essere confermata dalla segreteria studenti </span>
            <span v-else-if="user.Ruolo__c>4 && this.$i18n.locale=='en'">* It takes a few days to be confirmed by the student secretariat </span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-5 offset-1">
          <div class="form-group paddingpiu ">
            <input v-bind:class="{'is-invalid': !user.Privacy__c}" v-model="user.Privacy__c" class="form-check-input" type="checkbox" value="" id="defaultCheck1">
            <label class="form-check-label" for="privacy_acceptance" v-if="this.$i18n.locale=='it'">
              Ho letto e accetto <a href="https://www.alma.scuolacucina.it/privacy-policy/" target="blank_">
                <b>Informativa sulla Privacy</b></a>
            </label>
            <label class="form-check-label" for="privacy_acceptance" v-else>
              I have read and accepted <a href="https://www.alma.scuolacucina.it/privacy-policy/" target="blank_">
                <b>Privacy Policy</b></a>
            </label>
          </div>
        </div>
        <div class="col-3" >
          <button type="submit" class="btn btn-primary" v-on:click.prevent="doRegistrati" v-bind:disabled="!canSubmit">{{$t('Invia')}}</button>
          &nbsp;
          <button type="submit" class="btn btn-outline-secondary" v-on:click.prevent="$emit('cancel')">{{$t('Annulla')}}</button>
        </div>
      </div>

    </div>
  </div>


</template>

<script>
  export default {
    name: 'singup',
    props: {},
    computed: {
      canSubmit: function() {
        return this.user.username && this.user.password && this.user.PersonEmail && this.user.Privacy__c;
      }
    },
    data: function () {
      return {
        show: false,
        user: {
          username:'',
          password:'',

          FirstName:'',
          LastName:'',
          Data_Nascita__c:'',
          Sesso__c:'',
          Ruolo__c:'',
          Privacy__c:false,
          PersonEmail: '',
          PersonMobilePhone: '',
        }
      }
    },
    methods: {
      validateEmail: function(email) {
        // eslint-disable-next-line no-useless-escape
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
          return true;
        }
        return false;
      },
      async doRegistrati() {
        var data=this.user;
        console.log(this.$store.state.connection);
        var soql='select id from Account where Username__pc=\''+this.user.username+'\'';
        await this.$store.dispatch('checkUser', soql);
        if(this.$store.state.registrationDenied){
           alert('L\'username esiste già, recuperare password se necessario!');
           this.$emit('cancel');
           return;
         }else await this.$store.dispatch('register', data);

      } 
    }
  }
</script>

<style scoped>
</style>